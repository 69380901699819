import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { H4Text } from '../../../styles/common-styles.js';
import _ from 'lodash';

function MemberNames(props) {
  const { familyMembers, familyName, uniqueIdentifier } = props;

  const { isChurchAdmin } = useSelector((state) => state.ucdUser);

  const RenderFamilyMembers = () => {
    let familyMembersCSV = '';
    _.forEach(familyMembers, (value) => {
      if (value !== undefined) {
        if (familyMembersCSV.length > 0) {
          familyMembersCSV += ', ';
        }
        familyMembersCSV += value.first_name;
      }
    });
    return (
      <FamilyNameChildren numberOfLines={2}>
        {familyMembersCSV}
      </FamilyNameChildren>
    );
  };

  return (
    <FamilyNameContainer>
      <H4Text numberOfLines={1}>{familyName}</H4Text>
      {RenderFamilyMembers()}
      {isChurchAdmin && (
        <UniqueIdentifierDisplay>{uniqueIdentifier}</UniqueIdentifierDisplay>
      )}
    </FamilyNameContainer>
  );
}

export const FamilyNameContainer = styled.View`
  padding-left: 14px;
  padding-top: 14px;
  padding-bottom: 0px;
  height: 80px;
  width: 125px;
`;

export const FamilyNameChildren = styled.Text`
  font-size: 12px;
  font-family: sans-serif;
`;

export const UniqueIdentifierDisplay = styled.Text`
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  margin-top: 5px;
`;

export default MemberNames;
