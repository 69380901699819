import React, {useEffect, useState} from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import FamilyMemberInfo from './FamilyMemberInfo';

function FamilyMembersList() {
  const { familyMembers } = useSelector((state) => state.ucdFamilyMember);
  const [userCanEdit, setUserCanEdit ] = useState(false);

  console.log('Family Members:', familyMembers);
  const { isChurchAdmin } = useSelector(
    (state) => state.ucdUser
  );

  useEffect(() => {
    setUserCanEdit(isChurchAdmin)
  }, [isChurchAdmin]);

  let familyMembersElements;

  try{
      familyMembersElements = familyMembers.map((member, index) => 
        <FamilyMemberInfo key={index.toString()} item={member} />
      );
    
      console.log('family members list: ' , familyMembers.length)

    if(userCanEdit || (userCanEdit && familyMembers.length === 0) ){
      familyMembersElements.push(
        <FamilyMemberInfo key="custom" item={{}} />
      );
    }

  } catch(e){
    if(userCanEdit){
      familyMembersElements = [
        <FamilyMemberInfo key="custom" item={{}} />
        ]
    }

  }

  return (
    <FamilyMembersListContainer>
      {familyMembersElements}
    </FamilyMembersListContainer>
  )
}

export const FamilyMembersListContainer = styled.View`
  flex-direction:row;
  flex-wrap:wrap;
  width:100%;
  height:auto;
  overflow:visible;
` 

export default FamilyMembersList;
