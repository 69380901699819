import React, { useEffect } from 'react';
import { View, Text, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import _ from 'lodash';
import GroupListItem from './GroupListItem';
import { BasicRow } from '../../../styles/common-styles.js';

function GroupList() {
  const dispatch = useDispatch();
  const { churchGroupsList, selectedGroup } = useSelector(
    (state) => state.ucdChurchGroup
  );
  const { directoryFamilyList } = useSelector(
    (state) => state.ucdChurchDirectory
  );
  const { isChurchAdmin } = useSelector((state) => state.ucdUser);

  const renderItem = ({ item }) => {
    return <GroupListItem item={item} dispatch={dispatch} />;
  };

  return (
    <ListContainer>
      <ListHeader>
        <ListHeaderText>Name</ListHeaderText>
        {isChurchAdmin && <ListHeaderText>Email Group</ListHeaderText>}
        {isChurchAdmin && <ListHeaderText>Select for Removal</ListHeaderText>}
      </ListHeader>
      <FlatList
        data={churchGroupsList}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </ListContainer>
  );
}

const ListContainer = styled.View`
  flex: 1;
`;

const ListHeader = styled.View`
  height: 50px;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-horizontal: 10px;
`;

const ListHeaderText = styled.Text`
  font-size: 16px;
  font-weight: bold;
`;

export default GroupList;
