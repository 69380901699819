//Boiler plate code for a row of buttons for the admin page
import React, {useState} from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import CustomButtonPreview from './CustomButtonPreview';
import CustomButtonForm from './CustomButtonForm';

function CustomButtonRow({customButton, userChurchAppId, refreshListCallback, disabled}){

    const [formData, setFormData] = useState({
        churchId: userChurchAppId,
        label: customButton.label,
        color: customButton.color,
        urlLink: customButton.urlLink,
        enabled: customButton.enabled,
        _id: customButton._id,
    });

    console.log('disabled:' , disabled);

    return(
        (disabled ? (
            <CustomButtonRowContainerDisabled>
                <CustomButtonPreview customButton={formData}/>
                <CustomButtonForm customButton={formData} setCustomButton={setFormData} refreshListCallback={refreshListCallback} />
            </CustomButtonRowContainerDisabled>
        ) : (
            <CustomButtonRowContainer>
                <CustomButtonPreview customButton={formData}/>
                <CustomButtonForm customButton={formData} setCustomButton={setFormData} refreshListCallback={refreshListCallback} />
            </CustomButtonRowContainer>
        ))

    );
}

const CustomButtonRowContainer = styled.View`
    border: 3.5px solid lightgray;
    // border-top-width: 0px;
    // border-left-width: 0px;
    // border-right-width: 0px;
    // border-top-color: transparent;
    // border-left-color: transparent;
    // border-right-color: transparent;
    margin:5px;
    border-radius:15px;
    height:550px;
    width:450px;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
`;

const CustomButtonRowContainerDisabled = styled(CustomButtonRowContainer)`
    filter:grayscale(100%);
    pointer-events:none;
`;


export default CustomButtonRow;








