import React, { useEffect, useState, useMemo } from 'react';
import { View, Text, FlatList, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import _ from 'lodash';
import MemberCard from './MemberCard';
import { BasicRow } from '../../../styles/common-styles.js';
import { FlatGrid } from 'react-native-super-grid';

function MembersArea({ searchTerm }) {
  const dispatch = useDispatch();
  const { directoryFamilyList } = useSelector(
    (state) => state.ucdChurchDirectory
  );
  const { lastEditedFamilyImageId, lastEditTimestamp } = useSelector(
    (state) => state.ucdFamilyManagement
  );

  const filteredDirectoryFamilyList = useMemo(() => {
    if (searchTerm) {
      return directoryFamilyList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return directoryFamilyList;
  }, [directoryFamilyList, searchTerm]);

  const renderItem = useMemo(() => {
    console.log('render item useMemo Rerender');
    return ({ item }) => {
      const isImageNew = item.id === lastEditedFamilyImageId;
      return (
        <MemberCard
          item={item}
          dispatch={dispatch}
          isImageNew={isImageNew}
          editTimeStamp={lastEditTimestamp}
        />
      );
    };
  }, [dispatch, lastEditedFamilyImageId, lastEditTimestamp]);

  return (
    <MemberRow>
      <MemberGrid
        data={
          undefined === filteredDirectoryFamilyList
            ? []
            : filteredDirectoryFamilyList
        }
        itemDimension={140}
        adjustGridToStyles={true}
        renderItem={renderItem}
        keyExtractor={(item, index) => item.id}
      />
    </MemberRow>
  );
}

export const MemberRow = styled(BasicRow)`
  width: 100%;
  height: 100%;
  padding: 0;
`;

export const MemberGrid = styled(FlatGrid)`
  padding-left: 20px;
  padding-right: 40px;
  padding-top: 5px;
`;

export default MembersArea;
