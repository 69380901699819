import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFamilyMemberInfo } from '../../reducers/ucdFamilyMemberManagementSlice';
import { fetchFamiliesFromActiveDirectory } from '../../reducers/ucdChurchDirectorySlice';
import styled from 'styled-components/native';
import { Feather } from '@expo/vector-icons';
import validator from 'validator';

function EditFamilyMember({ familyMember, closeModal }) {
  const dispatch = useDispatch();
  const familyInfo = useSelector((state) => state.ucdFamilyMember);
  const { userChurchId } = useSelector((state) => state.ucdUser);

  const [formData, setFormData] = useState({
    firstName: familyMember.first_name,
    lastName:
      familyMember.last_name.length > 0
        ? familyMember.last_name
        : familyInfo.familyName,
    cellPhone: familyMember.cell,
    landlinePhone: familyMember.land,
    emailAddress: familyMember.email,
    familyMemberId: familyMember.id,
    familyId: familyInfo.id,
  });

  function handleChange(name, value) {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function validateUpdate() {
    // first name and last name are required
    const hasFirstName = validator.isLength(formData.firstName, { min: 1 });

    const hasLastName = validator.isLength(formData.lastName, {
      min: 1,
    });

    let validationErrorMessage = '';

    if (!hasFirstName || !hasLastName) {
      // form invalid
      validationErrorMessage = !hasFirstName ? 'First Name is required.\n' : '';
      validationErrorMessage += !hasLastName ? 'Last Name is required.\n' : '';
    }

    if (validator.isLength(formData.emailAddress, { min: 1 })) {
      if (!validator.isEmail(formData.emailAddress)) {
        // email is invalid
        validationErrorMessage += 'Email Address is not valid.';
      }
    }

    if (validationErrorMessage.length > 0) {
      alert(validationErrorMessage);
      return;
    }

    // submit the form if all good
    submitUpdate();
  }

  function submitUpdate() {
    dispatch(updateFamilyMemberInfo(formData))
      .then(() => {
        dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
      })
      .then(() => {
        closeModal();
      });
  }

  return (
    <AdminModalParent>
      <AdminModalContainer>
        <HeaderContainer>
          <AdminModalHeaderText>
            Update Family Member Information
          </AdminModalHeaderText>
          <CloseButton onPress={closeModal}>
            <Feather name='x-circle' size={38} color='#a51d31' />
          </CloseButton>{' '}
          {/* Close Button */}
        </HeaderContainer>

        <Form>
          <FormItem>
            <FormText>First Name:*</FormText>
            <FormInput
              value={formData.firstName}
              onChangeText={(value) => handleChange('firstName', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Last Name:*</FormText>
            <FormInput
              value={formData.lastName}
              onChangeText={(value) => handleChange('lastName', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Cell Phone:</FormText>
            <FormInput
              value={formData.cellPhone}
              onChangeText={(value) => handleChange('cellPhone', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Landline Phone:</FormText>
            <FormInput
              value={formData.landlinePhone}
              onChangeText={(value) => handleChange('landlinePhone', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Email Address:</FormText>
            <FormInput
              value={formData.emailAddress}
              onChangeText={(value) => handleChange('emailAddress', value)}
            />
          </FormItem>

          <FormSubmit onPress={validateUpdate}>
            <FormSubmitText>Submit</FormSubmitText>
          </FormSubmit>
        </Form>
      </AdminModalContainer>
    </AdminModalParent>
  );
}

export const AdminModalParent = styled.View`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminModalContainer = styled.View`
  padding: 20px;
  height: auto;
  width: auto;
  box-shadow: 0px 0px 5px black;
  background: #f2f2f2;
`;

export const AdminModalHeaderText = styled.Text`
  font-family: sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const Form = styled.View``;

export const FormItem = styled.View`
  margin-top: 15px;
`;

export const FormText = styled.Text`
  font-size: 16px;
  font-family: sans-serif;
`;

export const FormInput = styled.TextInput`
  width: 200px;
  height: 25px;
  background: white;
  padding: 2.5px;
  font-size: 14px;
  font-family: sans-serif;
  margin-top: 5px;
`;

export const FormSubmit = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FormSubmitText = styled.Text`
  font-family: sans-serif;
  font-size: 16px;
  color: white;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled.Text`
  font-size: 24px;
  cursor: pointer;
  margin-left: 15px;
`;

export default EditFamilyMember;
