import React, { useState, useEffect } from 'react';
import { View, Text, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { ChromePicker } from 'react-color';
import { createChurchCustomButton, updateChurchCustomButton } from '../../../reducers/ucdChurchCustomButtonSlice';

function CustomButtonForm({ customButton, setCustomButton, refreshListCallback }) {
    const dispatch = useDispatch();
    const { status, churchCustomButtons } = useSelector((state) => state.ucdChurchCustomButton);

    // Define state objects for button styles
    const [isChanged, setIsChanged] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('idle');

    function hasChanges() {
        const originalData = churchCustomButtons.find((button) => button._id === customButton._id);

        const originalDataCompObj = {
            churchId: originalData.churchId,
            label: originalData.label,
            color: originalData.color,
            urlLink: originalData.urlLink,
            enabled: originalData.enabled,
            _id: originalData._id
        }

        const customButtonCompObj = {
            churchId: customButton.churchId,
            label: customButton.label,
            color: customButton.color,
            urlLink: customButton.urlLink,
            enabled: customButton.enabled,
            _id: customButton._id
        }

        console.log(customButtonCompObj , originalDataCompObj);
        return JSON.stringify(customButtonCompObj) !== JSON.stringify(originalDataCompObj);
    }

    // Use the useEffect hook to update the button styles when the customButton object changes
    useEffect(() => {
        checkChanges();
    }, [customButton]);

    function checkChanges(){
        console.log('hasChanges:' , hasChanges());
        setIsChanged(hasChanges());
    }

    function handleChange(name, value) {
        const updatedData = {
            ...customButton,
            [name]: value,
        };
        setCustomButton(updatedData);
    }

    function cancelChanges() {
        const updatedData = churchCustomButtons.find((button) => button._id === customButton._id);
        console.log(updatedData);
        setCustomButton(updatedData);
        checkChanges();
    }

    function submitUpdate() {
        setButtonStatus('pending');

        if (customButton._id) {
            dispatch(updateChurchCustomButton(customButton)).then(() => {
              setButtonStatus('idle');
            });
          } else {
            dispatch(createChurchCustomButton(customButton)).then(() => {
              setButtonStatus('idle'); 
            });
          }
    }

    useEffect(() => {
        refreshListCallback();
    }, [status]);

    useEffect(() => {
        checkChanges();
    }, [churchCustomButtons]);

    return (
        <CustomButtonFormParent>
            <FormParent>
                <FormItem>
                    <FormText>Button Label:</FormText>
                    <FormInput value={customButton.label} onChangeText={(value) => handleChange('label', value)} />
                </FormItem>

                <FormItem>
                    <FormText>Button URL:</FormText>
                    <FormInput value={customButton.urlLink} onChangeText={(value) => handleChange('urlLink', value)} />
                </FormItem>

                <FormItem>
                    <FormText style={{ marginBottom: 5 }}>Button Color:</FormText>
                    <ChromePicker
                        width={200}
                        circleSize={24}
                        circleSpacing={6}
                        color={customButton.color}
                        onChangeComplete={(value) => handleChange('color', value.hex)}
                    />
                </FormItem>

                <FormItem>
                    <FormText>Enabled:</FormText>
                    <Switch
                        trackColor={{ false: '#767577', true: '#767577' }}
                        thumbColor={customButton.enabled ? '#f5dd4b' : '#f4f3f4'}
                        onValueChange={(value) => handleChange('enabled', value)}
                        value={customButton.enabled}
                        style={{ marginTop: 5 }}
                    />
                </FormItem>

                {buttonStatus === 'idle' && (
                    <ButtonContainer>
                        {isChanged ? (
                            <FormSubmit onPress={submitUpdate}>
                                <FormSubmitText>
                                    {customButton._id ? 'Save' : 'Create'}
                                </FormSubmitText>
                            </FormSubmit>
                        ) : (
                            <FormSubmitDisabled>
                                <FormSubmitText>
                                    {customButton._id ? 'Save' : 'Create'}
                                </FormSubmitText>
                            </FormSubmitDisabled>
                        )}

                        {isChanged ? (
                            <CancelButton onPress={cancelChanges}>
                                <CancelButtonText>Cancel</CancelButtonText>
                            </CancelButton>
                        ) : (
                            <CancelButtonDisabled>
                                <CancelButtonText>Cancel</CancelButtonText>
                            </CancelButtonDisabled>
                        )}
                    </ButtonContainer>
                )}
            </FormParent>
        </CustomButtonFormParent>
    );
}

const CustomButtonFormParent = styled.View`
    width: 500px;
    height: 100%;
    padding: 25px;
`;

export const FormParent = styled.View``;

export const FormItem = styled.View`
    margin-bottom: 15px;
`;

export const FormText = styled.Text`
    font-size: 16px;
    font-family: sans-serif;
`;

export const FormInput = styled.TextInput`
    width: 200px;
    height: 25px;
    background: white;
    padding: 2.5px;
    font-size: 14px;
    font-family: sans-serif;
    margin-top: 5px;
`;

export const ButtonContainer = styled.View`
    width: 210px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`;

export const FormSubmit = styled.TouchableOpacity`
    width: 100px;
    height: 35px;
    background: #a51d31;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

export const CancelButton = styled.TouchableOpacity`
    width: 100px;
    height: 35px;
    background: transparent;
    border: 2px solid #a51d31;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

export const FormSubmitText = styled.Text`
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: white;
`;

export const CancelButtonText = styled(FormSubmitText)`
    color: #a51d31;
`;

// New styled components for disabled buttons
const FormSubmitDisabled = styled(FormSubmit)`
    filter:grayscale(100%);
    pointer-events:none;
`;

const CancelButtonDisabled = styled(CancelButton)`
    filter:grayscale(100%);
    pointer-events:none;
`;

export default CustomButtonForm;
