import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import ucdSessionSlice from '../reducers/ucdSessionSlice';
import ucdUserSlice from '../reducers/ucdUserSlice';
import ucdChurchDirectorySlice from '../reducers/ucdChurchDirectorySlice';
import ucdChurchGroupSlice from '../reducers/ucdChurchGroupSlice';
import ucdModuleLoaderSlice from '../reducers/ucdModuleLoaderSlice';
import ucdFamilyMemberSlice from '../reducers/ucdFamilyMemberSlice';
import ucdAppSlice from '../reducers/ucdAppSlice';
import FamilyMemberManagementSlice from '../reducers/ucdFamilyMemberManagementSlice';
import ucdFamilyManagementSlice from '../reducers/ucdFamilyManagementSlice';
import ucdChurchCustomButtonSlice from '../reducers/ucdChurchCustomButtonSlice';
import ucdPushNotificationsSlice from "../reducers/ucdPushNotificationsSlice";
export const store = configureStore({
  reducer: {
    ucdSession: ucdSessionSlice,
    ucdUser: ucdUserSlice,
    ucdChurchDirectory: ucdChurchDirectorySlice,
    ucdChurchGroup: ucdChurchGroupSlice,
    ucdModuleLoader: ucdModuleLoaderSlice,
    ucdFamilyMember: ucdFamilyMemberSlice,
    ucdApp: ucdAppSlice,
    FamilyMemberManagement: FamilyMemberManagementSlice,
    ucdFamilyManagement: ucdFamilyManagementSlice,
    ucdChurchCustomButton: ucdChurchCustomButtonSlice,
    ucdPushNotifications: ucdPushNotificationsSlice,
  },
  enhancer: composeWithDevTools(),
});
