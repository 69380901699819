import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import ucdapi from '../api/ucdapi';
import _ from 'lodash';

/** STATE OBJECT */
const initialState = {
  familyMemberLoading: 'idle',
  familyMemberResultMessage: '',
  id: 0,
  familyName: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  phone: '',
  email: '',
  familyImage: '',
  uniqueIdentifier: '',
  familyMembers: [],
};

/** ASYNC ACTIONS */
export const addNewFamily = createAsyncThunk(
  'ucdFamilyMember/addNewFamily',
  async (familyInfo, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/add_new_family', {
      sessionKey: state.ucdSession.sessionKey,
      lastName: familyInfo.lastName,
      address: familyInfo.address,
      address2: '',
      city: familyInfo.city,
      state: familyInfo.state,
      zip: familyInfo.zip,
      phone: familyInfo.phone,
      emailAddress: familyInfo.emailAddress,
      fileName: 'none',
      churchId: state.ucdUser.userChurchId,
      directoryId: state.ucdChurchDirectory.directoryId,
    });

    // successful response?
    if (200 === response.status) {
      // endpoint returned success?
      if (response.data.success) {
        // send the family info to be added to the current family state object
        familyInfo.familyId = response.data.familyId;
        return familyInfo;
      } else {
        // return the error message
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdFamilyMemberSlice = createSlice({
  name: 'ucdFamilyMember',
  initialState,
  reducers: {
    setFamilyMemberLoadingStatus(state, { payload }) {
      state.familyMemberLoading = payload.loadingStatus;
      state.familyMemberResultMessage = payload.resultMessage;
    },
    setCurrentFamily(state, { payload }) {
      state.id = payload.id;
      state.familyName = payload.name;
      state.address =
        payload.address +
        (payload.address_2.length > 0 ? ' ' + payload.address_2 : '');
      state.city = payload.city;
      state.state = payload.state;
      state.postalCode = payload.zip;
      state.phone = payload.phone;
      state.email = payload.email;
      state.familyImage = payload.image;
      state.familyMembers = payload.members;
      state.uniqueIdentifier = payload.unique_identifier;
    },
    setCurrentFamilyUniqueIdentifier(state, { payload }) {
      state.uniqueIdentifier = payload;
    },
    clearCurrentFamily(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewFamily.pending, (state) => {
        state.familyMemberLoading = 'pending';
        state.familyMemberResultMessage = 'Saving New Family...';
      })
      .addCase(addNewFamily.fulfilled, (state, { payload }) => {
        state.familyMemberLoading = 'new-family-added';
        state.familyMemberResultMessage = 'Family Added';
        state.id = payload.familyId;
        state.familyName = payload.familyName;
        state.address = payload.address;
        state.city = payload.city;
        state.state = payload.state;
        state.postalCode = payload.postalCode;
        state.phone = payload.phone;
        state.email = payload.email;
        state.uniqueIdentifier = 'Pending';
      })
      .addCase(addNewFamily.rejected, (state, { payload }) => {
        state.familyMemberLoading = 'failed';
        state.familyMemberResultMessage = payload;
      });
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdFamilyMemberSlice;

export const {
  setFamilyMemberLoadingStatus,
  setCurrentFamily,
  clearCurrentFamily,
  setCurrentFamilyUniqueIdentifier,
} = actions;

// export the reducer
export default reducer;
