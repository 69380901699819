import React, { useEffect, useState } from 'react';
import { Modal, Text, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { fetchChurchGroups } from '../../reducers/ucdChurchGroupSlice';
import { Entypo } from '@expo/vector-icons';
import EmojiModal from 'react-native-emoji-modal';
import * as PropTypes from 'prop-types';
import { AudienceSelect } from './AudienceSelection';
import { GroupList } from './GroupList';
import { findNotificationsByChurchId } from '../../reducers/ucdPushNotificationsSlice';
import {
  sendNotificationToEntireChurch,
  sendNotificationToGroups,
  findChurchMainTopic,
  preSendNotification,
} from '../../reducers/ucdPushNotificationsSlice';

AudienceSelect.propTypes = {
  selectedAudience: PropTypes.any,
  onPress: PropTypes.func,
  onPress1: PropTypes.func,
};

GroupList.propTypes = {
  selectedAudience: PropTypes.any,
  churchGroupsList: PropTypes.any,
  prop2: PropTypes.func,
};

function CreateNotification({ fetchData }) {
  const dispatch = useDispatch();

  const [buttonStatus, setButtonStatus] = useState('idle');
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [notificationContent, setNotificationContent] = useState('');
  const [emojiModalVisible, setEmojiModalVisible] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [familiesToSendTo, setFamiliesToSendTo] = useState([]);

  const { userChurchAppId, isChurchAdmin } = useSelector(
    (state) => state.ucdUser
  );
  const { churchGroupsList } = useSelector((state) => state.ucdChurchGroup);
  const { churchMainTopicId, totalDevices, totalFamilies, lastMessageStatus } =
    useSelector((state) => state.ucdPushNotifications);
  const { directoryFamilyList } = useSelector(
    (state) => state.ucdChurchDirectory
  );

  const handleAudienceSelection = (audienceType) => {
    setSelectedAudience(audienceType);
  };

  const handleGroupSelect = (group) => {
    // does selectedGroups already contain the user selected group?
    if (selectedGroups.some((selected) => selected === group)) {
      // remove the user selected group from selectedGroup
      setSelectedGroups(
        selectedGroups.filter((selected) => selected !== group)
      );
    } else {
      // else add the selected groupId to the selectedGroups array
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  function getPreSendNotificationInformation() {
    setIsLoading(true);
    if (selectedAudience === 'Groups') {
      dispatch(preSendNotification({ groupIds: `${selectedGroups}` })).then(
        () => {
          setIsLoading(false);
          displaySendConfirmationModal();
        }
      );
    } else {
      let familyIdList = [];
      directoryFamilyList.forEach((item) => {
        familyIdList.push(item.mongoId);
      });
      setFamiliesToSendTo(familyIdList);
      dispatch(preSendNotification({ familyIds: familyIdList })).then(() => {
        setIsLoading(false);
        displaySendConfirmationModal();
      });
    }
  }

  function displaySendConfirmationModal() {
    setShowConfirmModal(true);
  }

  function displayStatusModal() {
    setShowStatusModal(true);
  }

  function sendNotification() {
    setShowConfirmModal(false);
    if (selectedAudience === 'Groups') {
      let payload = {
        churchId: `${userChurchAppId}`,
        notificationText: `${notificationContent}`,
        groups: [],
      };

      selectedGroups.forEach((item) => {
        console.log(item);
        payload.groups.push({ groupId: `${item}` });
      });
      dispatch(sendNotificationToGroups(payload)).then(() => {
        fetchData();
        displayStatusModal();
      });
    } else {
      let payload = {
        churchId: userChurchAppId,
        familyIds: familiesToSendTo,
        notificationText: notificationContent,
        snsTopicId: churchMainTopicId,
      };
      dispatch(sendNotificationToEntireChurch(payload)).then(() => {
        fetchData();
        displayStatusModal();
      });
    }

    restoreFields();
  }

  function restoreFields() {
    setSelectedAudience(null);
    setSelectedGroups([]);
    setNotificationContent('');
    setEmojiModalVisible(false);
  }

  useEffect(() => {
    dispatch(fetchChurchGroups(userChurchAppId));
    dispatch(findChurchMainTopic(userChurchAppId));
  }, []);

  return (
    <CreateNotificationParent>
      <AudienceSelect
        selectedAudience={selectedAudience}
        onPress={() => handleAudienceSelection('Everyone')}
        onPress1={() => handleAudienceSelection('Groups')}
      />

      <GroupList
        selectedAudience={selectedAudience}
        churchGroupsList={churchGroupsList}
        prop2={(group) => (
          <GroupItem key={group.churchGroupId}>
            <GroupItemText>{group.groupName}</GroupItemText>
            <input
              type='checkbox'
              checked={selectedGroups.some(
                (selected) => selected === group.churchGroupId
              )}
              onChange={() => handleGroupSelect(group.churchGroupId)}
            />
          </GroupItem>
        )}
      />

      {selectedAudience !== null && (
        <>
          <NotificationContentHeaderContainer>
            <NotificationContentHeader>
              Notification Content
            </NotificationContentHeader>
            <EmojiToggle
              popover={<Text>my explanation</Text>}
              onPress={() => {
                setEmojiModalVisible(emojiModalVisible ? false : true);
              }}
            >
              <Entypo name='emoji-happy' size={24} color='#a51d31' />
            </EmojiToggle>
          </NotificationContentHeaderContainer>
          <NotificationContentInput
            multiline={true}
            numberOfLines={4}
            maxLength={255}
            onChangeText={(text) => setNotificationContent(text)}
            value={notificationContent}
          />
          {emojiModalVisible && (
            <EmojiContainer>
              <EmojiModal
                onEmojiSelected={(emoji) => {
                  setNotificationContent(notificationContent + emoji);
                }}
                backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0)' }}
                containerStyle={{ width: '100%', height: '100%' }}
              />
            </EmojiContainer>
          )}
          {buttonStatus === 'idle' && (
            <ButtonContainer>
              {notificationContent !== '' &&
              (selectedAudience !== 'Groups' || selectedGroups.length !== 0) ? (
                <FormSubmit onPress={getPreSendNotificationInformation}>
                  <FormSubmitText>Send</FormSubmitText>
                </FormSubmit>
              ) : (
                <FormSubmitDisabled>
                  <FormSubmitText>Send</FormSubmitText>
                </FormSubmitDisabled>
              )}
              <CancelButton onPress={restoreFields}>
                <CancelButtonText>Cancel</CancelButtonText>
              </CancelButton>
            </ButtonContainer>
          )}
          {isLoading && (
            <ActivityIndicator
              size='large'
              color='#a51d31'
              style={{ marginTop: 25 }}
            />
          )}
        </>
      )}

      <Modal
        animationType='slide'
        transparent={true}
        visible={showConfirmModal}
        onRequestClose={() => {
          setShowConfirmModal(false);
        }}
      >
        <ConfirmModalParent>
          <ConfirmModalCont>
            {totalFamilies !== 0 ? (
              <>
                <ConfirmText>
                  Are you sure you want to send this notification? {'\n'} {'\n'}{' '}
                  {totalFamilies} Families and {totalDevices} Devices will
                  receive it.
                </ConfirmText>
                <ButtonContainer>
                  <FormSubmit onPress={sendNotification}>
                    <FormSubmitText>Send</FormSubmitText>
                  </FormSubmit>
                  <FormSubmit onPress={() => setShowConfirmModal(false)}>
                    <FormSubmitText>Cancel</FormSubmitText>
                  </FormSubmit>
                </ButtonContainer>
              </>
            ) : (
              <>
                <ConfirmText>
                  There are no devices setup to receive this notification in the
                  Groups you have selected.
                </ConfirmText>
                <ButtonContainer>
                  <FormSubmit onPress={() => setShowConfirmModal(false)}>
                    <FormSubmitText>Close</FormSubmitText>
                  </FormSubmit>
                </ButtonContainer>
              </>
            )}
          </ConfirmModalCont>
        </ConfirmModalParent>
      </Modal>

      <Modal
        animationType='slide'
        transparent={true}
        visible={showStatusModal}
        onRequestClose={() => {
          setShowStatusModal(false);
        }}
      >
        <ConfirmModalParent>
          <ConfirmModalCont>
            <ConfirmText>
              {lastMessageStatus === 'queued' &&
                'Your Notification has been added to the queue.'}
              {lastMessageStatus === 'complete' &&
                'Your Notification has been sent successfully.'}
              {lastMessageStatus === 'failed' &&
                'Failed to add your Notification to the queue.'}
            </ConfirmText>

            <ButtonContainer>
              <FormSubmit onPress={() => setShowStatusModal(false)}>
                <ConfirmButtonText>OK</ConfirmButtonText>
              </FormSubmit>
            </ButtonContainer>
          </ConfirmModalCont>
        </ConfirmModalParent>
      </Modal>
    </CreateNotificationParent>
  );
}
const CreateNotificationParent = styled.ScrollView`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const GroupItem = styled.View`
  width: 100%;
  height: 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom-width: 1px;
  border-bottom-color: lightgray;
`;

const GroupItemText = styled.Text`
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
`;

const NotificationContentHeaderContainer = styled.View`
  width: 350px;
  height: 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

const NotificationContentHeader = styled.Text`
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
`;

const EmojiToggle = styled.TouchableOpacity``;

const NotificationContentInput = styled.TextInput`
  width: 350px;
  height: 100px;
  border: 2px solid lightgray;
  border-radius: 5px;
  background: white;
  margin-top: 5px;
  padding: 5px;
  font-size: 13px;
  font-family: sans-serif;
  color: black;
`;

const EmojiContainer = styled.View`
  width: 350px;
  height: 300px;
  background: white;
`;

export const ButtonContainer = styled.View`
  width: 210px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const FormSubmit = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-right: 5px;
`;

export const CancelButton = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: transparent;
  border: 2px solid #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const FormSubmitText = styled.Text`
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
`;

export const CancelButtonText = styled(FormSubmitText)`
  color: #a51d31;
`;

const FormSubmitDisabled = styled(FormSubmit)`
  filter: grayscale(100%);
  pointer-events: none;
`;

const CancelButtonDisabled = styled(CancelButton)`
  filter: grayscale(100%);
  pointer-events: none;
`;

// Styled components for the confirmation modal
const ConfirmModalParent = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ConfirmModalCont = styled.View`
  width: 350px;
  height: 200px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 5px black;
  padding: 10px;
`;

const ConfirmText = styled.Text`
  color: black;
  font-size: 16px;
  font-family: sans-serif;
  margin-bottom: 20px;
  text-align: center;
`;

const ConfirmButton = styled.TouchableOpacity`
  background-color: #a51d31;
  padding: 10px;
  margin: 5px;
`;

const ConfirmButtonText = styled.Text`
  color: white;
  font-size: 18px;
`;

export default CreateNotification;
