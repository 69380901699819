import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import DataTableView from "./DataTableView";
import {findNotificationsByChurchId} from "../../reducers/ucdPushNotificationsSlice"
import {useDispatch, useSelector} from "react-redux";

function ViewPreviousNotifications({localPrevNotifs , fetchData}) {

    const dispatch = useDispatch();
    const { userChurchAppId } = useSelector((state) => state.ucdUser);

    useEffect(() => {
        fetchData(); // Initial fetch
        const intervalId = setInterval(fetchData, 10000); // Poll every 10 seconds
        return () => clearInterval(intervalId); // Cleanup
    }, [userChurchAppId]);

    return (
    <ViewPreviousNotificationsParent>
        <DataTableView data={localPrevNotifs}/>
    </ViewPreviousNotificationsParent>
  );
}

const ViewPreviousNotificationsParent = styled.View`
    width: 100%;
    height: 100%;
    padding:20px;
`;

export default ViewPreviousNotifications;
