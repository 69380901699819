import React from 'react';
import { View, Text, Modal, TouchableOpacity, StyleSheet } from 'react-native';

function CustomAlert(props) {
  return (
    <View style={styles.container}>
      <Modal animationType='fade' transparent>
        <View style={styles.modalContainer}>
          <View style={styles.alertContainer}>
            <Text style={styles.alertText}>{props.alertMessage}</Text>
            {props.closeAlert !== undefined && (
              <TouchableOpacity onPress={() => props.closeAlert()}>
                <Text style={styles.closeButton}>Close</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 10,
    backgroundColor: '#eaeaea',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
  },
  alertText: {
    fontSize: 18,
    marginBottom: 20,
  },
  closeButton: {
    fontSize: 18,
    color: 'blue',
    textAlign: 'center',
  },
});

export default CustomAlert;
