import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoadScreen from './LoadScreen';
import LoginArea from './login/LoginArea';
import MainAppArea from './MainAppArea';

const Stack = createStackNavigator();

function RouterComponent() {
  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name='loadScreen' component={LoadScreen} />
        <Stack.Screen name='login' component={LoginArea} />
        <Stack.Screen name='main' component={MainAppArea} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default RouterComponent;
