import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import NavigationItem from './NavigationItem';

function NavigationArea(props) {
  const [activeModule, setActiveModule] = useState('directory');
  const [userCanEdit, setUserCanEdit ] = useState(false);

  const { isChurchAdmin, activeFeatures } = useSelector(
    (state) => state.ucdUser
  );

  useEffect(() => {
    setUserCanEdit(isChurchAdmin)
  }, [isChurchAdmin]);

  return (
    <NavigationBar>
      {activeFeatures.directory &&
        <NavigationItem
          navIcon='grid-icon.png'
          moduleToLoad='directory'
          isActive={activeModule === 'directory'}
          setActiveModule={setActiveModule}
        />
      }
      {activeFeatures.groups && activeFeatures.directory &&
        <NavigationItem
          navIcon='group.png'
          moduleToLoad='groups'
          isActive={activeModule === 'groups'}
          setActiveModule={setActiveModule}
        />
      }

      { userCanEdit && activeFeatures.customButtons && activeFeatures.directory && ( // Custom button management
        <NavigationItem 
          navIcon='add.png'
          moduleToLoad='admin-custom-buttons' 
          isActive={activeModule === 'admin-custom-buttons'}  
          setActiveModule={setActiveModule} 
        />
      )}

      { userCanEdit && activeFeatures.pushNotifications && activeFeatures.directory && ( // Notification management
        <NavigationItem 
          navIcon='bell.png'
          moduleToLoad='notification-management' 
          isActive={activeModule === 'notification-management'}  
          setActiveModule={setActiveModule} 
        />
      )}

    </NavigationBar>
  );
}

const NavigationBar = styled.View`
  width:100px;
  background: #fff;
`;

export default NavigationArea;
