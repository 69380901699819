import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  familyUpdateLoading: 'idle',
  familyUpdateResultMessage: '',
};

/** ASYNC ACTIONS */
export const updateFamilyInfo = createAsyncThunk(
  'ucdChurchDirectory/updateFamilyInfo',
  async (familyInfo, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/update_family_info', {
      ...familyInfo,
      sessionKey: state.ucdSession.sessionKey,
      directoryId: state.ucdChurchDirectory.directoryId,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        console.log('Family Info Updated');
        return response.data.msg; // return the success message
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdChurchDirectorySlice = createSlice({
  name: 'ucdChurchDirectory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateFamilyInfo.pending, (state) => {
        state.familyUpdateLoading = 'pending';
        state.familyUpdateResultMessage = 'Updating Family Info...';
      })
      .addCase(updateFamilyInfo.fulfilled, (state, { payload }) => {
        state.familyUpdateLoading = 'idle';
        state.familyUpdateResultMessage = payload; // payload is the success message
      })
      .addCase(updateFamilyInfo.rejected, (state, { payload }) => {
        state.familyUpdateLoading = 'failed';
        state.familyUpdateResultMessage = payload; // payload is the error message
      });
  },
});

const { actions, reducer } = ucdChurchDirectorySlice;

export default reducer;
