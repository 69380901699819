import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, ScrollView, TextInput, Dimensions } from 'react-native';
import { DataTable } from 'react-native-paper';
import styled from 'styled-components/native';

function DataTableView(props) {
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [sortColumn, setSortColumn] = useState('dateTimeSent');
    const [sortDirection, setSortDirection] = useState('desc');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width); // New State for Window Width

    const itemsPerPage = 8; // Items per page
    const from = currentPage * itemsPerPage;
    const to = Math.min((currentPage + 1) * itemsPerPage, data.length);
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Function to update the width when the window size changes
    const onWindowChange = ({ window }) => {
        setWindowWidth(window.width);
    };

    // Listener to update the width when the window size changes
    useLayoutEffect(() => {
        Dimensions.addEventListener('change', onWindowChange);

        return () => {
            Dimensions.removeEventListener('change', onWindowChange);
        };
    }, []);

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const sortedData = sortData(sortColumn, sortDirection, props.data); // Sort data before setting it
            setData(sortedData);
            setInitialData(sortedData); // Also set the initialData as sorted
        }
    }, [props.data]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const sortData = (column, direction, inputData) => {
        return [...inputData].sort((a, b) => {
            let comparison = 0;
            if (a[column] > b[column]) comparison = 1;
            if (a[column] < b[column]) comparison = -1;
            return direction === 'asc' ? comparison : -comparison;
        });
    };

    const handleSort = (column) => {
        const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
        const sortedData = sortData(column, newDirection, data);
        setData(sortedData);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query) {
            const filteredData = initialData.filter((item) =>
                item.notificationText.toLowerCase().includes(query.toLowerCase())
            );
            setData(filteredData);
        } else {
            setData(initialData);
        }
    };

    const convertTime = (dtString) => {
        const utcDate = new Date(dtString);
        const localTimeString = utcDate.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });
        const [localDate, localTime] = localTimeString.split(', ');
        return `${localDate}\n${localTime}`;
    }

    return (
        <View style={{height:"100%", width:"100%"}}>
            <HeaderContainer>
                <PreviousNotificationHeader>Previous Notifications</PreviousNotificationHeader>
                <SearchBar
                    placeholder="Search by Message Contents"
                    value={searchQuery}
                    onChangeText={handleSearch}
                />
            </HeaderContainer>

            <ScrollView horizontal={windowWidth < 1700}>
                <DataTable style={{ width: windowWidth < 1700 ? 1150 : '100%' }}>
                    <DataTable.Header>
                        <DateTimeColHeader onPress={() => handleSort('dateTimeSent')}>Date / Time sent</DateTimeColHeader>
                        <GroupColHeader onPress={() => handleSort('groups')}>Groups sent to</GroupColHeader>
                        <MessageColHeader onPress={() => handleSort('notificationText')}>Message Contents</MessageColHeader>
                        <InfoColHeader onPress={() => handleSort('totalSent')}>Sent</InfoColHeader>
                        <InfoColHeader onPress={() => handleSort('totalFailedToSend')}>Failed</InfoColHeader>
                        <StatusColHeader onPress={() => handleSort('status')}>Status</StatusColHeader>
                    </DataTable.Header>
                    {data.slice(from, to).map((item, index) => {
                        try {
                            return (
                                <DataTable.Row key={index}>
                                    <DateTimeCol><p style={{fontSize:12}}>{convertTime(item.dateTimeSent)}</p></DateTimeCol>
                                    <GroupCol><p style={{fontSize:14}}>{item.groupNames}</p></GroupCol>
                                    <MessageCol><p style={{fontSize:14}}>{item.notificationText.substring(0, 255)}</p></MessageCol>
                                    <InfoCol><p style={{fontSize:14}}>{item.totalSent}</p></InfoCol>
                                    <InfoCol><p style={{fontSize:14}}>{item.totalFailedToSend}</p></InfoCol>
                                    <StatusCol><p style={{fontSize:14}}>{item.messageSendingStatus}</p></StatusCol>
                                </DataTable.Row>
                            );
                        } catch (e) {
                            return null;
                        }
                    })}
                    <DataTable.Pagination
                        page={currentPage}
                        numberOfPages={totalPages}
                        onPageChange={(page) => handlePageChange(page)}
                        label={`${from + 1}-${to} of ${data.length}`}
                    />
                </DataTable>
            </ScrollView>
        </View>
    );
}

// Styled components

const HeaderContainer = styled.View`
  width:100%;
  height:40px;
  display:flex;
  justify-content:space-between;
  align-items: flex-start;
  flex-direction: row;
`

const PreviousNotificationHeader = styled.Text`
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color:black;
`;

const SearchBar = styled.TextInput`
  width: 300px;
  height: 35px;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 5px;
`;

const DateTimeColHeader = styled(DataTable.Title)`
  flex: 0.3;
  cursor: pointer;
`;

const GroupColHeader = styled(DataTable.Title)`
  flex: 0.4;
  cursor: pointer;
`;

const MessageColHeader = styled(DataTable.Title)`
  flex: 2;
  cursor: pointer;
`;

const StatusColHeader = styled(DataTable.Title)`
  flex: 0.35;
  cursor: pointer;
`;

const InfoColHeader = styled(DataTable.Title)`
  flex:0.20;
  cursor: pointer;
`

const DateTimeCol = styled.Text`
  flex: 0.3;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
`;

const GroupCol = styled.View`
  flex: 0.4;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
`;

const MessageCol = styled.View`
  flex: 2;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
`;

const StatusCol = styled.View`
  flex: 0.35;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
`;

const InfoCol = styled.View`
  flex: 0.20;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
`;

export default DataTableView;
