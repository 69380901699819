import React, {useEffect, useState} from 'react';
import { View, Text, Modal } from 'react-native';
import styled from 'styled-components/native';
import { H4Text, AdminModalParent } from '../../../styles/common-styles.js';
import { FontAwesome } from '@expo/vector-icons'; 
import _ from 'lodash';
import { useSelector } from 'react-redux';
import EditFamilyInformation from '../../modals/EditFamilyInformation'

function FamilyDetailsName(props) {

  const { familyMembers, familyName } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [userCanEdit, setUserCanEdit ] = useState(false);

  const { isChurchAdmin } = useSelector(
    (state) => state.ucdUser
  );

  useEffect(() => {
    setUserCanEdit(isChurchAdmin)
  }, [isChurchAdmin]);

  const RenderFamilyMembers = () => {
    let familyMembersCSV = '';
    _.forEach(familyMembers, (value) => {
      if (value !== undefined) {
        if (familyMembersCSV.length > 0) {
          familyMembersCSV += ', ';
        }
        familyMembersCSV += value.first_name;
      }
    });
    return <FamilyDetailsChildren>{familyMembersCSV}</FamilyDetailsChildren>;
  };

  return (
    <>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <EditFamilyInformation closeModal={() => setModalVisible(false)}/>
      </Modal>
      {userCanEdit ? (
        <FamilyDetailsNameContainer>
          <EditContainer onPress={() => {
            setModalVisible(true);
          }}>
            <FontAwesome name="edit" size={30} color="#a51d31" />
          </EditContainer>
          <TextContainer>
            <FamilyDetailsNameHeader>{familyName}</FamilyDetailsNameHeader>
            {RenderFamilyMembers()}
          </TextContainer>
        </FamilyDetailsNameContainer>
      ) : (
        <FamilyDetailsNameContainer>
          <TextContainer>
            <FamilyDetailsNameHeader>{familyName}</FamilyDetailsNameHeader>
            {RenderFamilyMembers()}
          </TextContainer>
        </FamilyDetailsNameContainer>
      )}
    </>  
  );
}

export const FamilyDetailsNameContainer = styled.View`
  margin-left:17.5px;
  height:auto;
  width:auto;
  flex-direction:row;
`

export const FamilyDetailsNameHeader = styled.Text`
  font-size:26px;
  font-family:sans-serif;
  font-weight:bold;
`

export const FamilyDetailsChildren = styled.Text`
  font-size:18px;
  width:300px;
  font-family:sans-serif;
`

export const EditContainer = styled.TouchableOpacity`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-right:10px;
`

export const TextContainer = styled.View``

export default FamilyDetailsName;
