import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

function HorizontalRule() {
  return <HorizontalRuleStyle />;
}

const HorizontalRuleStyle = styled.View`
  margin-top:10px;
  margin-bottom:10px;
  height:1px;
  width:100%;
  background:#000;
`;

export default HorizontalRule;
