import styled from 'styled-components/native';

export const H5Text = styled.Text`
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
`;

export const H4Text = styled.Text`
  font-size: 20px;
  color:#000;
  font-weight:500;
  font-family:sans-serif;
`;

export const H3Text = styled.Text`
  font-size: 22px;
  font-weight: bold;
  font-family:sans-serif;
`;

export const H2Text = styled.Text`
  font-size: 24px;
  font-weight: bold;
  font-family:sans-serif;
`;

export const StandardText = styled.Text`
  font-size: 16px;
`;

export const CommonFormTextInput = styled.TextInput`
  width: 200px;
  height: 25px;
  border: 0.5px solid black;
  margin-top: 10px;
`;

export const CommonButton = styled.TouchableOpacity`
  background-color: rgb(165, 29, 49);
  padding: 10px 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const CommonButtonText = styled.Text`
  color: #fff;
  font-size: 16px;
`;

export const GridContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-between;
  flex-wrap: wrap;
`;

export const BasicRow = styled.View`
  flex-direction: row;
  flex: 1;
`;

export const VerticalLine = styled.View`
  width: 3px;
  height: 100%;
  background-color: #333;
  margin-left: 10px;
  margin-right: 10px;
`;
