import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import _ from 'lodash';
import { AntDesign } from '@expo/vector-icons';
import GroupList from './GroupList';
import AddGroupModal from './AddGroupModal';
import ConfirmModal from '../../custom/ConfirmModal';
import {
    fetchChurchGroups,
    addChurchGroup,
    removeChurchGroup,
    setChurchGroupLoadingStatus,
} from '../../../reducers/ucdChurchGroupSlice';
import {
    H2Text,
    CommonButton,
    CommonButtonText,
} from '../../../styles/common-styles.js';

function GroupListArea() {
    const dispatch = useDispatch();
    const { userChurchAppId, isChurchAdmin } = useSelector(
        (state) => state.ucdUser
    );
    const { churchGroupLoading, churchGroupResultMessage, churchGroupsList } =
        useSelector((state) => state.ucdChurchGroup);

    const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
    const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);

    useEffect(() => {
        dispatch(fetchChurchGroups(userChurchAppId));
    }, []);

    useEffect(() => {
        if (churchGroupLoading === 'removed' || churchGroupLoading === 'removalerror') {
            setShowRemovalConfirmation(false);
        }
    }, [churchGroupLoading]);

    const anyGroupsSelected = _.findIndex(churchGroupsList, { selected: true });

    return (
        <GroupListAreaContainer>
            <AddGroupModal
                showModal={openAddGroupModal}
                saveGroup={(groupName) => dispatch(addChurchGroup(groupName)).then(() => {dispatch(fetchChurchGroups(userChurchAppId));})}
                setModalVisible={setOpenAddGroupModal}
                setGroupStateStatus={() =>
                    dispatch(
                        setChurchGroupLoadingStatus({
                            loadingStatus: 'idle',
                            resultMessage: '',
                        })
                    )
                }
            />
            <ConfirmModal
                isWaiting={'removing' === churchGroupLoading ? true : false}
                isSuccess={'removed' === churchGroupLoading ? true : false}
                isError={'removalerror' === churchGroupLoading ? true : false}
                alertMessage={churchGroupResultMessage}
                resultType={'removed' === churchGroupLoading ? 'success' : 'error'}
                showConfirmationMessageAndButtons={'idle' === churchGroupLoading}
                showConfirmation={showRemovalConfirmation}
                confirmButtonText='Yes'
                declineButtonText='No'
                confirmationMessage='Are you sure you want to remove the selected groups?'
                confirmAction={() => dispatch(removeChurchGroup({})).then(() => {dispatch(fetchChurchGroups(userChurchAppId));})}
            />
            <HeaderContainer>
                <H2Text>Groups</H2Text>
            </HeaderContainer>
            <GroupListContainer>
                <GroupList />
            </GroupListContainer>
            {isChurchAdmin && (
                <ButtonsWrapper>
                    <ButtonContainer onPress={() => setOpenAddGroupModal(true)}>
                        <IconContainer>
                            <AntDesign name='pluscircle' size={24} color='#fff' />
                        </IconContainer>
                        <ButtonText>Add a Group</ButtonText>
                    </ButtonContainer>
                    <ButtonContainer
                        onPress={() => setShowRemovalConfirmation(true)}
                        disabled={anyGroupsSelected !== -1 ? false : true}
                        opacityValue={anyGroupsSelected !== -1 ? 1 : 0.7}
                    >
                        <IconContainer>
                            <AntDesign name='minuscircle' size={24} color='#fff' />
                        </IconContainer>
                        <ButtonText>Remove Selected Groups</ButtonText>
                    </ButtonContainer>
                </ButtonsWrapper>
            )}
        </GroupListAreaContainer>
    );
}

const GroupListAreaContainer = styled.View`
  background:white;
  flex: 1;
  padding:15px;
`;

const HeaderContainer = styled.View`
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

const GroupListContainer = styled.View`
  flex: 1;
`;

const ButtonsWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled(TouchableOpacity)`
  background-color: rgb(165, 29, 49);
  padding: 10px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  opacity: ${({ opacityValue }) => opacityValue};
`;

const IconContainer = styled.View`
  margin-right: 10px;
`;

const ButtonText = styled(Text)`
  color: #ffffff;
  font-size: 16px;
`;

export default GroupListArea;
