import React, { useState, useEffect } from 'react';
import { View, Text, Modal, TextInput, Button } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import WaitMessage from './WaitMessage';
import AlertMessage from './AlertMessage';
import {
  CommonButton,
  CommonFormTextInput,
  CommonButtonText,
} from '../../styles/common-styles.js';

function ConfirmModal(props) {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setModalVisible(props.showConfirmation);
  }, [props.showConfirmation]);

  return (
    <Modal
      visible={modalVisible}
      animationType='slide'
      onRequestClose={() => setModalVisible(false)}
      transparent
    >
      <ModalContainer>
        <ModalContent>
          {props.isWaiting && <WaitMessage message={props.alertMessage} />}
          {(props.isSuccess || props.isError) && (
            <>
              <AlertMessage
                message={props.alertMessage}
                alertType={props.resultType}
              />
              <ButtonContainer>
                <CommonButton onPress={() => setModalVisible(false)}>
                  <CommonButtonText>Close</CommonButtonText>
                </CommonButton>
              </ButtonContainer>
            </>
          )}
          {props.showConfirmationMessageAndButtons && (
            <>
              <Text>{props.confirmationMessage}</Text>
              <ButtonContainer>
                <CommonButton onPress={() => props.confirmAction()}>
                  <CommonButtonText>{props.confirmButtonText}</CommonButtonText>
                </CommonButton>
                <CommonButton onPress={() => setModalVisible(false)}>
                  <CommonButtonText>{props.declineButtonText}</CommonButtonText>
                </CommonButton>
              </ButtonContainer>
            </>
          )}
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
}

const ModalContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.View`
  flex: 0.2;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 80%;
  align-items: center;
`;

const ButtonContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default ConfirmModal;
