import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'react-native';
import { updateChurchExtraInformation } from '../../reducers/ucdUserSlice';
import styled from 'styled-components/native';
import { Feather } from '@expo/vector-icons';
import _ from 'lodash';
import validator from 'validator';

function EditChurchInformation({ closeModal }) {
  const dispatch = useDispatch();

  const { churchExtraInfo, userChurchId, userChurchName } = useSelector(
    (state) => state.ucdUser
  );

  const [formData, setFormData] = useState({
    name: userChurchName,
    address: churchExtraInfo.address,
    city: churchExtraInfo.city,
    state: churchExtraInfo.state,
    zipCode: churchExtraInfo.zipCode,
    primaryPhone: churchExtraInfo.primaryPhone,
    primaryEmail: churchExtraInfo.primaryEmail,
    denomination: churchExtraInfo.denomination,
    website: churchExtraInfo.website,
    staffLink: churchExtraInfo.staffLink,
    facebook: churchExtraInfo.facebook,
    instagram: churchExtraInfo.instagram,
    twitter: churchExtraInfo.twitter,
    announcements: churchExtraInfo.announcements,
    eventCalendar: churchExtraInfo.eventCalendar,
    prayerRequests: churchExtraInfo.prayerRequests,
    streamingLink: churchExtraInfo.streamingLink,
    serviceTimes: churchExtraInfo.serviceTimes,
  });

  function handleChange(name, value) {
    // Updated to work with React Native TextInput
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function validateUpdate() {
    // church name must exist

    const hasChurchName = validator.isLength(formData.name, {
      min: 1,
    });

    let validationErrorMessage = '';

    if (!hasChurchName) {
      // form invalid
      validationErrorMessage += 'Church Name is required';
    }

    if (validator.isLength(formData.primaryEmail, { min: 1 })) {
      if (!validator.isEmail(formData.primaryEmail)) {
        // email is invalid
        validationErrorMessage += 'Email Address is not valid.';
      }
    }

    if (validationErrorMessage.length > 0) {
      alert(validationErrorMessage);
      return;
    }

    // submit the form if all good
    submitUpdate();
  }

  function submitUpdate() {
    dispatch(updateChurchExtraInformation(formData)).then(() => {
      closeModal();
    });
  }

  return (
    <AdminModalParent>
      <AdminModalContainer>
        <HeaderContainer>
          <AdminModalHeaderText>Update Church Information</AdminModalHeaderText>
          <CloseButton onPress={closeModal}>
            <Feather name='x-circle' size={38} color='#a51d31' />
          </CloseButton>{' '}
          {/* Close Button */}
        </HeaderContainer>

        <Form>
          <FormRow>
            <FormColumn>
              <FormItem>
                <FormText>Church Name:</FormText>
                <FormInput
                  value={formData.name}
                  onChangeText={(value) => handleChange('name', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Street Address:</FormText>
                <FormInput
                  value={formData.address}
                  onChangeText={(value) => handleChange('address', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>City:</FormText>
                <FormInput
                  value={formData.city}
                  onChangeText={(value) => handleChange('city', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>State:</FormText>
                <FormInput
                  value={formData.state}
                  onChangeText={(value) => handleChange('state', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Zip Code:</FormText>
                <FormInput
                  value={formData.zipCode}
                  onChangeText={(value) => handleChange('zipCode', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Phone #:</FormText>
                <FormInput
                  value={formData.primaryPhone}
                  onChangeText={(value) => handleChange('primaryPhone', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Email:</FormText>
                <FormInput
                  value={formData.primaryEmail}
                  onChangeText={(value) => handleChange('primaryEmail', value)}
                />
              </FormItem>
            </FormColumn>
            <FormColumn>
              <FormItem>
                <FormText>Denomination:</FormText>
                <FormInput
                  value={formData.denomination}
                  onChangeText={(value) => handleChange('denomination', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Website:</FormText>
                <FormInput
                  value={formData.website}
                  onChangeText={(value) => handleChange('website', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Staff Link:</FormText>
                <FormInput
                  value={formData.staffLink}
                  onChangeText={(value) => handleChange('staffLink', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Facebook:</FormText>
                <FormInput
                  value={formData.facebook}
                  onChangeText={(value) => handleChange('facebook', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>X/Twitter:</FormText>
                <FormInput
                  value={formData.twitter}
                  onChangeText={(value) => handleChange('twitter', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Instagram:</FormText>
                <FormInput
                  value={formData.instagram}
                  onChangeText={(value) => handleChange('instagram', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Announcements:</FormText>
                <FormInput
                  value={formData.announcements}
                  onChangeText={(value) => handleChange('announcements', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Event Calendar:</FormText>
                <FormInput
                  value={formData.eventCalendar}
                  onChangeText={(value) => handleChange('eventCalendar', value)}
                />
              </FormItem>

              <FormItem>
                <FormText>Online Giving:</FormText>
                <FormInput
                  value={formData.prayerRequests}
                  onChangeText={(value) =>
                    handleChange('prayerRequests', value)
                  }
                />
              </FormItem>

              <FormItem>
                <FormText>Streaming Link:</FormText>
                <FormInput
                  value={formData.streamingLink}
                  onChangeText={(value) => handleChange('streamingLink', value)}
                />
              </FormItem>
            </FormColumn>
          </FormRow>

          <FormSubmit onPress={validateUpdate}>
            <FormSubmitText>Submit</FormSubmitText>
          </FormSubmit>
        </Form>
      </AdminModalContainer>
    </AdminModalParent>
  );
}

export const FormRow = styled.View`
  flex-direction: row;
  margin-bottom: 16px;
`;

export const FormColumn = styled.View`
  flex: 1;
  margin-right: ${(props) => (props.last ? '0' : '16px')};
`;

export const AdminModalParent = styled.View`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminModalContainer = styled.View`
  padding: 20px;
  height: auto;
  width: 45%;
  box-shadow: 0px 0px 5px black;
  background: #f2f2f2;
`;

export const AdminModalHeaderText = styled.Text`
  font-family: sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const Form = styled.View``;

export const FormItem = styled.View`
  margin-top: 15px;
`;

export const FormText = styled.Text`
  font-size: 16px;
  font-family: sans-serif;
`;

export const FormInput = styled.TextInput`
  width: 100%;
  height: 25px;
  background: white;
  padding: 2.5px;
  font-size: 14px;
  font-family: sans-serif;
  margin-top: 5px;
`;

export const FormSubmit = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FormSubmitText = styled.Text`
  font-family: sans-serif;
  font-size: 16px;
  color: white;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled.Text`
  font-size: 24px;
  cursor: pointer;
  margin-left: 15px;
`;

export default EditChurchInformation;
