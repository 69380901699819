import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, TouchableOpacity, Modal } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { H2Text } from '../../styles/common-styles.js';
import EditChurchInformation from '../modals/EditChurchInformation';

function HeaderTitle(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);

  const { userChurchName, isChurchAdmin } = useSelector(
    (state) => state.ucdUser
  );

  useEffect(() => {
    setUserCanEdit(isChurchAdmin);
  }, [isChurchAdmin]);

  return (
    <>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <EditChurchInformation closeModal={() => setModalVisible(false)} />
      </Modal>
      <HeaderTitleContainer>
        <HeaderTitleText>
          <MyStyle>my</MyStyle> CHURCH DIRECTORY: {userChurchName}{' '}
          {userCanEdit && (
            <TouchableOpacity onPress={() => setModalVisible(true)}>
              <FontAwesome name='edit' size={30} color='#a51d31' />
            </TouchableOpacity>
          )}
        </HeaderTitleText>
      </HeaderTitleContainer>
    </>
  );
}

const HeaderTitleContainer = styled.View`
  margin-left: 25px;
  flex: 1;
  flex-direction: row;
`;

export const HeaderTitleText = styled.Text`
  font-size: 1.5em;
  font-weight: bold;
`;

export const MyStyle = styled.Text`
  font-size: 1.6em;
  line-height: 80%;
`;

export default HeaderTitle;
