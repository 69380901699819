import React from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import GroupListArea from './list/GroupListArea';
import GroupMembersArea from './members/GroupMembersArea';

function GroupsArea() {
  return (
    <GroupsAreaContainer>
      <GroupsAreaLeftColumn>
        <GroupListArea />
      </GroupsAreaLeftColumn>
      <GroupsAreaRightColumn>
        <GroupMembersArea />
      </GroupsAreaRightColumn>
    </GroupsAreaContainer>
  );
}

const GroupsAreaContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const GroupsAreaLeftColumn = styled.View`
  flex: 1;
  overflow: scroll;
  background-color: #f1f1ee;
`;

const GroupsAreaRightColumn = styled.View`
  flex: 2;
  overflow: scroll;
`;

export default GroupsArea;
