import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import MemberPhoto from './MemberPhoto';
import MemberNames from './MemberNames';
import { setCurrentFamily } from '../../../reducers/ucdFamilyMemberSlice';
import { useSelector } from 'react-redux';

import { BasicRow } from '../../../styles/common-styles.js';

function MemberCard(props) {
  const { item, dispatch, isImageNew, editTimeStamp } = props;
  useEffect(() => {
    if (isImageNew == true) {
      console.log('new image?', isImageNew, editTimeStamp);
    }
  }, [isImageNew, editTimeStamp]);
  return (
    <CardContainer>
      <MemberInfoContainer onPress={() => dispatch(setCurrentFamily(item))}>
        <MemberPhoto
          imageName={item.image}
          bustCache={isImageNew}
          cacheTimestamp={editTimeStamp}
        />
        <MemberNames
          familyMembers={item.members}
          familyName={item.name}
          uniqueIdentifier={item.unique_identifier}
        />
      </MemberInfoContainer>
    </CardContainer>
  );
}

const CardContainer = styled.View`
  width: 100%;
  height: auto;
  background-color: #fff;
  filter: drop-shadow(0px 0px 2px #555);
  border-radius: 2.5px;
`;

export const MemberInfoContainer = styled.TouchableOpacity``;

export default MemberCard;
