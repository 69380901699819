import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ucdmongoapi from '../api/ucdmongoapi';

/** STATE OBJECT */
const initialState = {
  churchCustomButtons: [],
  status: 'idle',
  error: null,
};

/** ASYNC ACTIONS */

// Action to create a church custom app button
export const createChurchCustomButton = createAsyncThunk(
  'customChurchButtons/create',
  async (buttonData, { rejectWithValue }) => {
    const response = await ucdmongoapi.post('/api/churchcustomappbuttons/create', buttonData);

    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

// Action to find custom app buttons by church id
export const findChurchCustomButtons = createAsyncThunk(
  'customChurchButtons/findByChurchId',
  async (searchData, { rejectWithValue }) => {
    const response = await ucdmongoapi.post('/api/churchcustomappbuttons/findbychurchid', searchData);

    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

// Action to update a church custom app button
export const updateChurchCustomButton = createAsyncThunk(
  'customChurchButtons/update',
  async (updateData, { rejectWithValue }) => {
    const response = await ucdmongoapi.put('/api/churchcustomappbuttons/update', updateData);

    if (response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const customChurchButtonsSlice = createSlice({
  name: 'customChurchButtons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createChurchCustomButton.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(createChurchCustomButton.fulfilled, (state, action) => {
        state.status = 'idle';
        state.churchCustomButtons.push(action.payload);
      })
      .addCase(createChurchCustomButton.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(findChurchCustomButtons.fulfilled, (state, action) => {
        state.status = 'idle';
        state.churchCustomButtons = action.payload;
      })
      .addCase(updateChurchCustomButton.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(updateChurchCustomButton.fulfilled, (state, action) => {
        state.status = 'idle';
        const updatedButton = action.payload;
        const index = state.churchCustomButtons.findIndex((button) => button._id === updatedButton._id);
        if (index >= 0) {
          state.churchCustomButtons[index] = updatedButton;
        }
      });
  },
});

export default customChurchButtonsSlice.reducer;
