import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  directoryLoading: 'idle',
  directoryResultMessage: '',
  directoryId: 0,
  directoryFamilyList: [],
};

/** ASYNC ACTIONS */
export const fetchFamiliesFromActiveDirectory = createAsyncThunk(
  'ucdChurchDirectory/fetchFamiliesFromActiveDirectory',
  async (churchId, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/get_families', {
      church_id: churchId,
      session_key: state.ucdSession.sessionKey,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        //console.log('Families', response.data.families);
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdChurchDirectorySlice = createSlice({
  name: 'ucdChurchDirectory',
  initialState,
  reducers: {
    updateFamilyUniqueIdentifier(state, { payload }) {
      state.directoryFamilyList[payload.index].unique_identifier =
        payload.newUniqueIdentifier;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamiliesFromActiveDirectory.pending, (state) => {
        state.directoryLoading = 'pending';
        state.directoryResultMessage = 'Loading Families from Directory...';
      })
      .addCase(
        fetchFamiliesFromActiveDirectory.fulfilled,
        (state, { payload }) => {
          state.directoryLoading = 'idle';
          state.directoryResultMessage = '';
          (state.directoryId = payload.directoryId),
            (state.directoryFamilyList = payload.families);
        }
      )
      .addCase(
        fetchFamiliesFromActiveDirectory.rejected,
        (state, { payload }) => {
          state.directoryLoading = 'failed';
          state.directoryResultMessage = payload;
        }
      );
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdChurchDirectorySlice;

export const { updateFamilyUniqueIdentifier } = actions;

// export the reducer
export default reducer;
