import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import _ from 'lodash';
import MemberPhoto from '../members-list/MemberPhoto';
import MemberNames from '../members-list/MemberNames';
import MainMemberContactInfo from './MainMemberContactInfo';
import FamilyMembersList from './FamilyMembersList';
import FamilyDetailsPhoto from './FamilyDetailsPhoto';
import FamilyDetailsName from './FamilyDetailsName';
import { MaterialCommunityIcons, AntDesign } from '@expo/vector-icons';
import {
  issueNewFamilyAccessCode,
  emailAccessCodes,
  setFamilyActiveStatus,
  setFamilyActiveStatusFeedback,
} from '../../../reducers/ucdFamilyManagementSlice';
import {
  setCurrentFamily,
  clearCurrentFamily,
  setCurrentFamilyUniqueIdentifier,
} from '../../../reducers/ucdFamilyMemberSlice';
import {
  fetchFamiliesFromActiveDirectory,
  updateFamilyUniqueIdentifier,
} from '../../../reducers/ucdChurchDirectorySlice';

function MemberDetailsArea() {
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  const { isChurchAdmin, userChurchId } = useSelector((state) => state.ucdUser);
  const { sessionKey } = useSelector((state) => state.ucdSession);
  const { id, familyImage, familyMembers, familyName, uniqueIdentifier } =
    useSelector((state) => state.ucdFamilyMember);
  const { familyMemberLoading, familyMemberResultMessage } = useSelector(
    (state) => state.FamilyMemberManagement
  );
  const { directoryFamilyList, directoryId } = useSelector(
    (state) => state.ucdChurchDirectory
  );

  const { familyActiveStatusLoading, newFamilyAccessCode } = useSelector(
    (state) => state.ucdFamilyManagement
  );

  const dispatch = useDispatch();

  // Function to update the current family member's details
  const updateCurrentFamily = () => {
    const updatedFamily = directoryFamilyList.find(
      (family) => family.id === id
    );
    if (updatedFamily) {
      dispatch(setCurrentFamily(updatedFamily));
    }
  };

  useEffect(() => {
    setUserCanEdit(isChurchAdmin);
  }, [isChurchAdmin]);

  useEffect(() => {
    updateCurrentFamily();
    setRenderKey((prevKey) => prevKey + 1);
  }, [directoryFamilyList]);

  useEffect(() => {
    if ('complete' === familyActiveStatusLoading) {
      dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
      dispatch(
        setFamilyActiveStatusFeedback({
          loadingStatus: 'idle',
          resultMessage: '',
        })
      );
      dispatch(clearCurrentFamily());
    }
  }, [familyActiveStatusLoading]);

  useEffect(() => {
    if (newFamilyAccessCode.length > 0) {
      dispatch(setCurrentFamilyUniqueIdentifier(newFamilyAccessCode));
      // find the family in the directory and update the unique identifier in the list
      const familyToUpdateIndex = _.findIndex(directoryFamilyList, { id: id });
      if (familyToUpdateIndex > -1) {
        dispatch(
          updateFamilyUniqueIdentifier({
            index: familyToUpdateIndex,
            newUniqueIdentifier: newFamilyAccessCode,
          })
        );
      }
    }
  }, [newFamilyAccessCode]);

  function createNewIDCode() {
    var confirmCreate = window.confirm(
      'Are you sure you want to create a new ID code for this family?'
    );
    if (confirmCreate) {
      dispatch(
        issueNewFamilyAccessCode({ familyId: id, sessionKey: sessionKey })
      );
    }
  }

  function resendIDCode() {
    var confirmResend = window.confirm(
      'Are you sure you want to resend the ID code for this family?'
    );
    if (confirmResend) {
      dispatch(
        emailAccessCodes({
          sessionKey: sessionKey,
          accessCodeFor: 'individual',
          familyId: id,
        })
      );
    }
  }

  function deleteFamily() {
    var confirmDelete = window.confirm(
      'Are you sure you want to delete this family?'
    );
    if (confirmDelete) {
      dispatch(
        setFamilyActiveStatus({
          sessionKey: sessionKey,
          isActive: 'N',
          familyId: id,
          directoryId: directoryId,
          churchId: userChurchId,
        })
      );
    }
  }

  try {
    if (familyMembers.length === 0) {
      return;
    }
  } catch (e) {}

  return (
    <FamilyContainer key={renderKey}>
      <FamilyFirstRow>
        {userCanEdit && (
          <AdminToolsContainer>
            <AdminToolRow>
              <AntDesign name='idcard' size={24} color='#a51d31' />
              <AdminText>{uniqueIdentifier}</AdminText>
            </AdminToolRow>
            <AdminTool onPress={createNewIDCode}>
              <MaterialCommunityIcons
                name='refresh'
                size={24}
                color='#a51d31'
              />
              <AdminText>Create new ID code</AdminText>
            </AdminTool>
            <AdminTool onPress={resendIDCode}>
              <MaterialCommunityIcons
                name='email-send-outline'
                size={24}
                color='#a51d31'
              />
              <AdminText>Resend ID code</AdminText>
            </AdminTool>
            <AdminTool onPress={deleteFamily}>
              <MaterialCommunityIcons
                name='trash-can-outline'
                size={24}
                color='#a51d31'
              />
              <AdminText>Delete Family</AdminText>
            </AdminTool>
          </AdminToolsContainer>
        )}
        <FamilyImageContainer>
          <FamilyDetailsPhoto imageName={familyImage} />
        </FamilyImageContainer>
        <FamilyDetailsContainer>
          <FamilyDetailsName
            familyMembers={familyMembers}
            familyName={familyName}
          />
          <MainMemberContactInfo />
        </FamilyDetailsContainer>
      </FamilyFirstRow>
      <FamilyRow>
        <FamilyMembersList />
      </FamilyRow>
    </FamilyContainer>
  );
}

const FamilyContainer = styled.View`
  width: 100%;
  height: 100%;
  padding: 25px;
`;

const FamilyFirstRow = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 57px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border: 3.5px solid #a51d31;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
`;

const FamilyImageContainer = styled.View`
  margin-right: 8px;
`;

const FamilyDetailsContainer = styled.View``;

const FamilyRow = styled.View`
  flex-direction: row;
  overflow: visible;
`;

const AdminToolsContainer = styled.View`
  position: absolute;
  top: 0;
  right: 0;
`;

const AdminTool = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;

const AdminToolRow = styled.Text`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;

const AdminText = styled.Text`
  font-family: sans-serif;
  font-size: 14px;
  margin-left: 10px;
`;

export default MemberDetailsArea;
