import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Modal,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import styled from 'styled-components/native';
import DirectorySearch from './DirectorySearch';
import MembersArea from './members-list/MembersArea';
import MemberDetailsArea from './member-details/MemberDetailsArea';
import EditFamilyInformation from '../modals/EditFamilyInformation';
import { fetchFamiliesFromActiveDirectory } from '../../reducers/ucdChurchDirectorySlice';
import { clearCurrentFamily } from '../../reducers/ucdFamilyMemberSlice';

function DirectoryArea() {
  const dispatch = useDispatch();

  // Extract the necessary parts of the state
  const { isChurchAdmin, userChurchId } = useSelector((state) => state.ucdUser);
  const directoryLoading = useSelector(
    (state) => state.ucdChurchDirectory.directoryLoading
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    console.log('User Church Id', userChurchId);
    if (userChurchId) {
      dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
    }
  }, [userChurchId]);

  useEffect(() => {
    setUserCanEdit(isChurchAdmin);
  }, [isChurchAdmin]);

  return (
    <>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <EditFamilyInformation closeModal={() => setModalVisible(false)} />
      </Modal>
      <DirectoryAreaContainer>
        <DirectoryAreaColumn
          style={{
            width: '55%',
          }}
        >
          <DirectorySearch setSearchTerm={setSearchTerm} />
          {userCanEdit && (
            <StyledRowTouchableOpacity
              onPress={() => {
                dispatch(clearCurrentFamily());
                setModalVisible(true);
              }}
            >
              <FontAwesome name='plus-square-o' size={30} color='#a51d31' />
              <StyledText>Add New Family</StyledText>
            </StyledRowTouchableOpacity>
          )}
          <MembersAreaContainer>
            <MembersArea
              style={{
                width: '100%',
              }}
              searchTerm={searchTerm}
            />
            {directoryLoading === 'pending' && (
              <ActivityIndicatorContainer>
                <ActivityIndicator size='large' />
              </ActivityIndicatorContainer>
            )}
          </MembersAreaContainer>
        </DirectoryAreaColumn>
        <DirectoryAreaColumn
          style={{
            width: '45%',
            overflowY: 'scroll',
            backgroundColor: '#fff',
          }}
        >
          <MemberDetailsArea />
        </DirectoryAreaColumn>
      </DirectoryAreaContainer>
    </>
  );
}

const DirectoryAreaContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const DirectoryAreaColumn = styled.View`
  width: 50%;
  padding: 0px;
`;

const MembersAreaContainer = styled.View`
  flex: 1;
`;

const ActivityIndicatorContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgb(242, 242, 242);
`;

const StyledRowTouchableOpacity = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 20px;
`;

const StyledText = styled.Text`
  margin-left: 10px;
  font-size: 18px;
`;

export default DirectoryArea;
