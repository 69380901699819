import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import {
  H4Text,
  CommonFormTextInput,
  CommonButton,
  CommonButtonText,
} from '../../styles/common-styles.js';
import { checkUserAccessCode } from '../../reducers/ucdUserSlice';

function EnterAccessCodeForm() {
  const dispatch = useDispatch();
  const accessCodeInputRef = useRef(null);

  return (
    <LoginSecurityContainer>
      <H4Text>
        If you are logging in for the first time, please enter your church
        provided unique identifier (for the mobile app access) to create your
        account.
      </H4Text>
      <CommonFormTextInput
        autoCorrect={false}
        autoCapitalize='none'
        ref={accessCodeInputRef}
        placeholder='UNIQUE IDENTIFIER'
      />
      {'  '}
      <CommonButton
        onPress={() =>
          dispatch(checkUserAccessCode(accessCodeInputRef.current.value))
        }
      >
        <CommonButtonText>GO</CommonButtonText>
      </CommonButton>
    </LoginSecurityContainer>
  );
}

const LoginSecurityContainer = styled.View`
  flex: 1;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
  color: #111;
  height: 25%;
  align-items: center;
`;

export default EnterAccessCodeForm;
