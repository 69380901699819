import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { Image } from 'react-native-elements';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { ActivityIndicator } from 'react-native';
import { checkImageURI } from '../../../utils';
import {
  imageHostURI,
  serverImageHostURI,
  DEFAULT_IMAGE,
} from '../../../config.js';

function MemberPhoto(props) {
  const { userChurchAppId } = useSelector((state) => state.ucdUser);
  const { imageName, bustCache, cacheTimestamp } = props;
  const [imageUri, setImageUri] = useState(
    `${serverImageHostURI}${userChurchAppId}/${imageName}`
  );
  const [isValidURI, setIsValidURI] = useState(true);

  useEffect(() => {
    if (bustCache) {
      console.log('BUSTING CACHE', bustCache, imageName);
      const newUri = `${serverImageHostURI}${userChurchAppId}/${imageName}?time=${new Date().getTime()}`;
      setImageUri(newUri);
      console.log('NEW URI', newUri);
    }
  }, [bustCache, cacheTimestamp]);

  useEffect(() => {
    checkImageURI(imageUri).then((isValid) => {
      setIsValidURI(isValid);
    });
  }, [imageUri]);

  const source = isValidURI
    ? { uri: imageUri }
    : require('../../../../icon/NO-IMG.jpg');

  return (
    <Image
      style={{
        height: '200px',
        width: '100%',
        resizeMode: 'cover',
      }}
      source={source}
      defaultSource={require('../../../../icon/NO-IMG.jpg')}
      PlaceholderContent={<ActivityIndicator />}
    />
  );
}

export const MemberPhotoCont = styled.Image`
  height: 200px;
  width: 100%;
  resizemode: cover;
  border-radius-top-left: 2.5px;
  border-radius-top-right: 2.5px;
`;

export default MemberPhoto;
