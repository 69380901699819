import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

function CustomButtonPreview({customButton}){
    
    function openButtonURL(){
        window.open(customButton.urlLink, '_blank');
    }
    
    return(
        <CustomButtonPreviewParent>
            <Text style={{marginBottom:25}}>Preview</Text>
            <CustomButton onPress={() => {openButtonURL()}} color={customButton.color}>
            </CustomButton>
            <CustomButtonText>{customButton.label ? customButton.label : 'Label Text'}</CustomButtonText>
        </CustomButtonPreviewParent>
    );
}

const CustomButtonPreviewParent = styled.View`
    width:175px;
    height:300px;
    justify-content:center;
    align-items:center;
    border: 3.5px solid lightgray;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
`;

const CustomButton = styled.TouchableOpacity`
    width:100px;
    height:100px;
    background: ${props => props.color ? props.color : '#a51d31'};
    border-radius:5px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:5px;
    border-radius:50px;
    box-shadow: 0px 1.5px 7.5px rgba(0,0,0,0.75);
`;

const CustomButtonText = styled.Text`
    width:100px;
    font-family:sans-serif;
    font-weight:600;
    font-size:12px;
    margin-top:10px;
    color:black;
    word-wrap:break-word;
    text-align:center;
`;

export default CustomButtonPreview;








