import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  userId: 0,
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  isChurchAdmin: false,
  userChurchId: '',
  userChurchAppId: '',
  userChurchName: '',
  userFamilyName: '',
  userLoading: 'idle',
  userResultMessage: '',
  accessCodeValid: false,
  loggingOut: false,
  activeFeatures: {
    directory: true,
    groups: false,
    pushNotifications: false,
    customButtons: false,
  },
  churchExtraInfo: {
    churchAddressInfoId: 0,
    address: '',
    city: '',
    state: '',
    zipCode: '',
    primaryPhone: '',
    primaryEmail: '',
    denomination: '',
    website: '',
    staffLink: '',
    facebook: '',
    instagram: '',
    twitter: '',
    announcements: '',
    eventCalendar: '',
    prayerRequests: '',
    streamingLink: '',
    serviceTimes: '',
  },
};

/** ASYNC ACTIONS */
export const checkUserAccessCode = createAsyncThunk(
  'ucdUser/checkUserAccessCode',
  async (accessCode, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/check_key', {
      app_key: accessCode,
      session_key: state.ucdSession.sessionKey,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const signUpUser = createAsyncThunk(
  'ucdUser/signUpUser',
  async (
    { userName, password, email, firstName, lastName },
    { rejectWithValue, getState }
  ) => {
    const state = getState();

    // make sure all parameters have values
    const allFieldsAreValid = await allSignupFieldsValid({
      userName,
      password,
      email,
      firstName,
      lastName,
    });

    if (!allFieldsAreValid) {
      return rejectWithValue('All Fields Are Required');
    }

    const response = await ucdapi.post('/user_signup', {
      session_key: state.ucdSession.sessionKey,
      uname: userName,
      pword: password,
      email: email,
      firstname: firstName,
      lastname: lastName,
      church_id: state.ucdUser.userChurchId,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

const allSignupFieldsValid = async (signupFields) => {
  const { userName, password, email, firstName, lastName } = signupFields;
  if (0 === userName.length) {
    return false;
  }

  if (0 === password.length) {
    return false;
  }

  if (0 === email.length) {
    return false;
  }

  if (0 === firstName.length) {
    return false;
  }

  if (0 === lastName.length) {
    return false;
  }

  return true;
};

export const loginUser = createAsyncThunk(
  'ucdUser/loginUser',
  async ({ userName, password }, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/get_user', {
      uname: userName,
      pword: password,
      session_key: state.ucdSession.sessionKey,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  'ucdUser/changeUserPassword',
  async ({ userId, password }, { rejectWithValue, getState }) => {
    const state = getState();

    const response = await ucdapi.post('/change_password', {
      session_key: state.ucdSession.sessionKey,
      user_id: userId,
      pword: password,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the data
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const verifyAdminAccessToken = createAsyncThunk(
  'ucdUser/verifyAdminAccessToken',
  async (accessToken, { rejectWithValue, getState }) => {
    const state = getState();
    console.log('Access Token to Verify', accessToken);
    const response = await ucdapi.post('/validate_access_token', {
      sessionKey: state.ucdSession.sessionKey,
      accessToken: accessToken,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.tokenValid) {
        // return the data
        return response.data;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.errorMessage);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const setUserAndCheckAppFeaturesActive = createAsyncThunk(
  'ucdUser/setUserAndCheckAppFeaturesActive',
  async (userData, { rejectWithValue, dispatch, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/fetch_church_info', {
      sessionKey: state.ucdSession.sessionKey,
      churchId: userData.userChurchId,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // set the user and then update the church app features that are active
        dispatch(setUser(userData));
        return response.data.churchInfo;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.message);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const updateChurchExtraInformation = createAsyncThunk(
  'ucdUser/updateChurchExtraInformation',
  async (churchInfoData, { rejectWithValue, getState }) => {
    const state = getState();
    const churchInfoToSubmit = {
      sessionKey: state.ucdSession.sessionKey,
      churchId: state.ucdUser.userChurchId,
      churchInfoId: state.ucdUser.churchExtraInfo.churchAddressInfoId,
      name: churchInfoData.name,
      address: churchInfoData.address,
      city: churchInfoData.city,
      state: churchInfoData.state,
      zipCode: churchInfoData.zipCode,
      phone: churchInfoData.primaryPhone,
      email: churchInfoData.primaryEmail,
      denomination: churchInfoData.denomination,
      website: churchInfoData.website,
      facebook: churchInfoData.facebook,
      instagram: churchInfoData.instagram,
      twitter: churchInfoData.twitter,
      announcementsLink: churchInfoData.announcements,
      eventCalendarLink: churchInfoData.eventCalendar,
      prayerRequestsLink: churchInfoData.prayerRequests,
      streamingServiceLink: churchInfoData.streamingLink,
      staffLink: churchInfoData.staffLink,
      directoryId: state.ucdChurchDirectory.directoryId,
    };

    const response = await ucdapi.post(
      '/update_church_info',
      churchInfoToSubmit
    );

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the church information to update the state object
        return churchInfoToSubmit;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdUserSlice = createSlice({
  name: 'ucdUser',
  initialState,
  reducers: {
    setUserLoadingStatus(state, { payload }) {
      state.userLoading = payload.loadingStatus;
      state.userResultMessage = payload.resultMessage;
    },
    setAccessCodeValid(state, { payload }) {
      state.accessCodeValid = payload;
    },
    setUser(state, { payload }) {
      state.userId = payload.userId;
      state.isChurchAdmin = payload.isChurchAdmin;
      state.userChurchId = payload.userChurchId;
      state.userChurchAppId = payload.userChurchAppId;
      state.userChurchName = payload.userChurchName;
      state.userFamilyName = payload.userFamilyName;
      state.userFirstName = payload.userFirstName;
      state.userLastName = payload.userLastName;
      state.userEmail = payload.userEmail;
    },
    logoutUser(state) {
      state.loggingOut = true;
    },
    resetUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserAccessCode.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Verifying Access Code...';
      })
      .addCase(checkUserAccessCode.fulfilled, (state, { payload }) => {
        state.userLoading = 'idle';
        state.userResultMessage = '';
        state.userChurchId = payload.church_id;
        state.userFamilyName = payload.family_name;
        state.userEmail = payload.email;
        state.userChurchName = payload.church_name;
        state.accessCodeValid = true;
      })
      .addCase(checkUserAccessCode.rejected, (state, { payload }) => {
        console.log('Rejected', payload);
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      })
      .addCase(signUpUser.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Signing You Up...';
      })
      .addCase(signUpUser.fulfilled, (state, { payload }) => {
        state.userLoading = 'signup-success';
        state.userResultMessage = 'Signup Completed';
      })
      .addCase(signUpUser.rejected, (state, { payload }) => {
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Logging You In...';
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.userLoading = 'idle';
        state.userResultMessage = '';
        state.isChurchAdmin = 'N' === payload.regular_user;
        state.userChurchId = payload.church_id;
        state.userChurchName = payload.church_name;
        state.userChurchAppId = payload.church_app_id;
        state.userId = payload.user_id;
        state.userFirstName = payload.first_name;
        state.userLastName = payload.last_name;
        state.userEmail = payload.email;
        state.activeFeatures.directory =
          'true' === payload.app_features_active.directory ||
          true === payload.app_features_active.directory;
        state.activeFeatures.groups =
          'true' === payload.app_features_active.groups ||
          true === payload.app_features_active.groups;
        state.activeFeatures.pushNotifications =
          'true' === payload.app_features_active.pushNotifications ||
          true === payload.app_features_active.pushNotifications;
        state.activeFeatures.customButtons =
          'true' === payload.app_features_active.customButtons ||
          true === payload.app_features_active.customButtons;
        state.churchExtraInfo.churchAddressInfoId =
          payload.church_extra_info.church_info_id;
        state.churchExtraInfo.address = payload.church_extra_info.address;
        state.churchExtraInfo.city = payload.church_extra_info.city;
        state.churchExtraInfo.state = payload.church_extra_info.state;
        state.churchExtraInfo.zipCode = payload.church_extra_info.zip_code;
        state.churchExtraInfo.primaryPhone =
          payload.church_extra_info.primary_phone;
        state.churchExtraInfo.primaryEmail =
          payload.church_extra_info.primary_email;
        state.churchExtraInfo.denomination =
          payload.church_extra_info.denomination;
        state.churchExtraInfo.website = payload.church_extra_info.website;
        state.churchExtraInfo.staffLink = payload.church_extra_info.staff_link;
        state.churchExtraInfo.facebook = payload.church_extra_info.facebook;
        state.churchExtraInfo.instagram = payload.church_extra_info.instagram;
        state.churchExtraInfo.twitter = payload.church_extra_info.twitter;
        state.churchExtraInfo.announcements =
          payload.church_extra_info.announcements;
        state.churchExtraInfo.eventCalendar =
          payload.church_extra_info.event_calendar;
        state.churchExtraInfo.prayerRequests =
          payload.church_extra_info.prayer_requests;
        state.churchExtraInfo.streamingLink =
          payload.church_extra_info.streaming_link;
        state.churchExtraInfo.serviceTimes =
          payload.church_extra_info.service_times;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Updating Your Password...';
      })
      .addCase(changeUserPassword.fulfilled, (state, { payload }) => {
        state.userLoading = 'password-success';
        state.userResultMessage = 'Password Successfully Changed';
      })
      .addCase(changeUserPassword.rejected, (state, { payload }) => {
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      })
      .addCase(verifyAdminAccessToken.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Verifying Access Token...';
      })
      .addCase(verifyAdminAccessToken.fulfilled, (state, { payload }) => {
        state.userLoading = 'idle';
        state.userResultMessage = '';
        state.isChurchAdmin = true;
        state.userChurchId = payload.churchId;
        state.userChurchName = payload.churchName;
        state.userChurchAppId = payload.churchAppId;
        state.activeFeatures.directory =
          'true' === payload.appFeaturesActive.directory ||
          true === payload.appFeaturesActive.directory;
        state.activeFeatures.groups =
          'true' === payload.appFeaturesActive.groups ||
          true === payload.appFeaturesActive.groups;
        state.activeFeatures.pushNotifications =
          'true' === payload.appFeaturesActive.pushNotifications ||
          true === payload.appFeaturesActive.pushNotifications;
        state.activeFeatures.customButtons =
          'true' === payload.appFeaturesActive.customButtons ||
          true === payload.appFeaturesActive.customButtons;
        state.userId = payload.userId;
        state.churchExtraInfo.churchAddressInfoId =
          payload.church_extra_info.church_info_id;
        state.churchExtraInfo.address = payload.church_extra_info.address;
        state.churchExtraInfo.city = payload.church_extra_info.city;
        state.churchExtraInfo.state = payload.church_extra_info.state;
        state.churchExtraInfo.zipCode = payload.church_extra_info.zip_code;
        state.churchExtraInfo.primaryPhone =
          payload.church_extra_info.primary_phone;
        state.churchExtraInfo.primaryEmail =
          payload.church_extra_info.primary_email;
        state.churchExtraInfo.denomination =
          payload.church_extra_info.denomination;
        state.churchExtraInfo.website = payload.church_extra_info.website;
        state.churchExtraInfo.staffLink = payload.church_extra_info.staff_link;
        state.churchExtraInfo.facebook = payload.church_extra_info.facebook;
        state.churchExtraInfo.instagram = payload.church_extra_info.instagram;
        state.churchExtraInfo.twitter = payload.church_extra_info.twitter;
        state.churchExtraInfo.announcements =
          payload.church_extra_info.announcements;
        state.churchExtraInfo.eventCalendar =
          payload.church_extra_info.event_calendar;
        state.churchExtraInfo.prayerRequests =
          payload.church_extra_info.prayer_requests;
        state.churchExtraInfo.streamingLink =
          payload.church_extra_info.streaming_link;
        state.churchExtraInfo.serviceTimes =
          payload.church_extra_info.service_times;
      })
      .addCase(verifyAdminAccessToken.rejected, (state, { payload }) => {
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      })
      .addCase(setUserAndCheckAppFeaturesActive.pending, (state) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Fetching Church Info...';
      })
      .addCase(
        setUserAndCheckAppFeaturesActive.fulfilled,
        (state, { payload }) => {
          state.userLoading = 'idle';
          state.activeFeatures.directory =
            'true' === payload.app_features_active.directory ||
            true === payload.app_features_active.directory;
          state.activeFeatures.groups =
            'true' === payload.app_features_active.groups ||
            true === payload.app_features_active.groups;
          state.activeFeatures.pushNotifications =
            'true' === payload.app_features_active.pushNotifications ||
            true === payload.app_features_active.pushNotifications;
          state.activeFeatures.customButtons =
            'true' === payload.app_features_active.customButtons ||
            true === payload.app_features_active.customButtons;
          state.churchExtraInfo.churchAddressInfoId =
            payload.church_extra_info.church_info_id;
          state.churchExtraInfo.address = payload.church_extra_info.address;
          state.churchExtraInfo.city = payload.church_extra_info.city;
          state.churchExtraInfo.state = payload.church_extra_info.state;
          state.churchExtraInfo.zipCode = payload.church_extra_info.zip_code;
          state.churchExtraInfo.primaryPhone =
            payload.church_extra_info.primary_phone;
          state.churchExtraInfo.primaryEmail =
            payload.church_extra_info.primary_email;
          state.churchExtraInfo.denomination =
            payload.church_extra_info.denomination;
          state.churchExtraInfo.website = payload.church_extra_info.website;
          state.churchExtraInfo.staffLink =
            payload.church_extra_info.staff_link;
          state.churchExtraInfo.facebook = payload.church_extra_info.facebook;
          state.churchExtraInfo.instagram = payload.church_extra_info.instagram;
          state.churchExtraInfo.twitter = payload.church_extra_info.twitter;
          state.churchExtraInfo.announcements =
            payload.church_extra_info.announcements;
          state.churchExtraInfo.eventCalendar =
            payload.church_extra_info.event_calendar;
          state.churchExtraInfo.prayerRequests =
            payload.church_extra_info.prayer_requests;
          state.churchExtraInfo.streamingLink =
            payload.church_extra_info.streaming_link;
          state.churchExtraInfo.serviceTimes =
            payload.church_extra_info.service_times;
        }
      )
      .addCase(
        setUserAndCheckAppFeaturesActive.rejected,
        (state, { payload }) => {
          state.userLoading = 'failed';
          state.userResultMessage = payload;
        }
      )
      .addCase(updateChurchExtraInformation.pending, (state, { payload }) => {
        state.userLoading = 'pending';
        state.userResultMessage = 'Saving Church Information...';
      })
      .addCase(updateChurchExtraInformation.fulfilled, (state, { payload }) => {
        state.userLoading = 'church-info-updated-success';
        state.userResultMessage = 'Church Information Successfully Changed';
        state.userChurchName = payload.name;
        state.churchExtraInfo.churchAddressInfoId = payload.churchAddressInfoId;
        state.churchExtraInfo.address = payload.address;
        state.churchExtraInfo.city = payload.city;
        state.churchExtraInfo.state = payload.state;
        state.churchExtraInfo.zipCode = payload.zipCode;
        state.churchExtraInfo.primaryPhone = payload.phone;
        state.churchExtraInfo.primaryEmail = payload.email;
        state.churchExtraInfo.denomination = payload.denomination;
        state.churchExtraInfo.website = payload.website;
        state.churchExtraInfo.staffLink = payload.staffLink;
        state.churchExtraInfo.facebook = payload.facebook;
        state.churchExtraInfo.instagram = payload.instagram;
        state.churchExtraInfo.twitter = payload.twitter;
        state.churchExtraInfo.announcements = payload.announcementsLink;
        state.churchExtraInfo.eventCalendar = payload.eventCalendarLink;
        state.churchExtraInfo.prayerRequests = payload.prayerRequestsLink;
        state.churchExtraInfo.streamingLink = payload.streamingServiceLink;
        state.churchExtraInfo.serviceTimes = payload.serviceTimes;
      })
      .addCase(updateChurchExtraInformation.rejected, (state, { payload }) => {
        state.userLoading = 'failed';
        state.userResultMessage = payload;
      });
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdUserSlice;

export const {
  setUserLoadingStatus,
  setAccessCodeValid,
  setUser,
  logoutUser,
  resetUser,
} = actions;

// export the reducer
export default reducer;
