import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import processImage from 'blueimp-canvas-to-blob';
import { uploadFamilyImage } from '../../reducers/ucdFamilyManagementSlice';
import styled from 'styled-components/native';
import { Image, TouchableOpacity } from 'react-native';
import { manipulateAsync } from 'expo-image-manipulator';
import { fetchFamiliesFromActiveDirectory } from '../../reducers/ucdChurchDirectorySlice';
import { Feather } from '@expo/vector-icons';

function EditFamilyImage({ closeModal, initialImage }) {
  const { sessionKey } = useSelector((state) => state.ucdSession);
  const familyInfo = useSelector((state) => state.ucdFamilyMember);
  const { userChurchAppId, userChurchId } = useSelector(
    (state) => state.ucdUser
  );
  const { directoryId } = useSelector((state) => state.ucdChurchDirectory);

  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (initialImage) {
      const cacheBustedImage = `${initialImage}?cb=${Date.now()}`;
      setImageSrc(cacheBustedImage);
      console.log('initialImage', initialImage, cacheBustedImage);
    }
  }, [initialImage]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    },
  });

  const rotateImage = async () => {
    try {
      // Use the manipulateAsync function to rotate the image by 90 degrees
      const manipResult = await manipulateAsync(imageSrc, [{ rotate: 90 }]);

      // Update the imageSrc state with the new URI
      setImageSrc(manipResult.uri);
    } catch (error) {
      console.error('An error occurred while rotating the image:', error);
    }
  };

  const submitImage = () => {
    // Convert the Base64 string to a Blob
    const byteString = atob(imageSrc.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' }); // Assuming the image is JPEG

    // Prepare the FormData object
    const formData = new FormData();

    formData.append('sessionKey', sessionKey);
    formData.append('familyId', familyInfo.id);
    formData.append('churchIdFromApp', userChurchAppId);
    formData.append('directoryId', directoryId);
    formData.append('familyPhoto', blob); // Append the Blob as a file

    dispatch(
      uploadFamilyImage({ formData: formData, familyId: familyInfo.id })
    ).then((status) => {
      console.log('success?', status);
      dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
    });
    closeModal();
  };

  return (
    <AdminModalParent>
      <AdminModalContainer>
        <HeaderContainer>
          <AdminModalHeaderText>Upload Family Image</AdminModalHeaderText>
          <CloseButton onPress={closeModal}>
            <Feather name='x-circle' size={38} color='#a51d31' />
          </CloseButton>
        </HeaderContainer>
        <FormContainer>
          {imageSrc && (
            <ImagePreview>
              <Image
                source={{ uri: imageSrc }}
                resizeMode='contain'
                style={{ width: 275, height: 400 }}
              />
            </ImagePreview>
          )}
          <Form>
            <FormItem {...getRootProps()}>
              <input type='file' {...getInputProps()} />
              <FormText>Click to select an image</FormText>
            </FormItem>

            <FormSubmitContainer>
              <FormSubmit onPress={rotateImage}>
                <FormSubmitText>Rotate</FormSubmitText>
              </FormSubmit>
              <FormSubmit onPress={submitImage}>
                <FormSubmitText>Submit</FormSubmitText>
              </FormSubmit>
            </FormSubmitContainer>
          </Form>
        </FormContainer>
      </AdminModalContainer>
    </AdminModalParent>
  );
}

export const AdminModalParent = styled.View`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminModalContainer = styled.View`
  padding: 20px;
  height: auto;
  width: auto;
  box-shadow: 0px 0px 5px black;
  background: #f2f2f2;
`;

export const AdminModalHeaderText = styled.Text`
  font-family: sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const FormContainer = styled.View`
  width: auto;
  height: auto;
  flex-direction: row;
`;
export const Form = styled.View`
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormItem = styled.View`
  border: 2px dashed lightgray;
  height: 335px;
  padding: 25px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FormText = styled.Text`
  font-size: 16px;
  font-family: sans-serif;
`;

export const FormInput = styled.TextInput`
  width: 200px;
  height: 25px;
  background: white;
  padding: 2.5px;
  font-size: 14px;
  font-family: sans-serif;
  margin-top: 5px;
`;

export const ImagePreview = styled.View`
  margin-top: 15px;
`;

export const FormSubmitContainer = styled.View`
  width: 225px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FormSubmit = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FormSubmitText = styled.Text`
  font-family: sans-serif;
  font-size: 16px;
  color: white;
`;
export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled.Text`
  font-size: 24px;
  cursor: pointer;
  margin-left: 15px;
`;

export default EditFamilyImage;
