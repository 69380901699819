import React from 'react';
import { View, Text, TextInput } from 'react-native';
import styled from 'styled-components/native';
import { H2Text, CommonFormTextInput } from '../../styles/common-styles.js';

function SearchBox(props) {
  return (
    <SearchContainer>
      <LabelText>{props.label}</LabelText>
      <SearchBar
        autoCorrect={false}
        autoCapitalize='none'
        placeholder='Search'
        onChangeText={(text) => props.changeAction(text)}
      />
    </SearchContainer>
  );
}

const SearchContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LabelText = styled(H2Text)`
  margin-left: 15px;
  margin-right: 15px;
`;

const SearchBar = styled.TextInput`
  margin-top: 7px;
  height: 30px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid black;
  height: 20px;
  padding-left: 30px;
  font-size: 1em;
`;

export default SearchBox;
