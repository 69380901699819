import React, {useEffect, useState} from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { A } from '@expo/html-elements';
import styled from 'styled-components/native';
import HorizontalRule from '../../HorizontalRule';

function MainMemberContactInfo() {
  const { address, city, state, postalCode, phone, email } = useSelector(
    (state) => state.ucdFamilyMember
  );

  const contactInfo = [
    {
      check: address || (city && state && postalCode),
      element: (
        <ContactInfoContainer>
          <IconContainer>
            <Ionicons name="location-outline" size={30} color="#a51d31" />
          </IconContainer>
          <ContactInfo>
            <ContactInfoTextHeader>Address</ContactInfoTextHeader>
            <ContactText>{address}</ContactText>
            <ContactText>
              {city}, {state} {postalCode}
            </ContactText>
          </ContactInfo>
        </ContactInfoContainer>
      ),
    },
    {
      check: phone,
      element: (
        <ContactInfoContainer>
          <IconContainer>
            <AntDesign name="phone" size={30} color="#a51d31" />
          </IconContainer>
          <ContactInfo>
            <ContactInfoTextHeader>Phone</ContactInfoTextHeader>
            <A style={{
              fontFamily: 'sans-serif'
            }} href={`tel:${phone}`}>{phone}</A>
          </ContactInfo>
        </ContactInfoContainer>
      ),
    },
    {
      check: email,
      element: (
        <ContactInfoContainer>
          <IconContainer>
            <AntDesign name="mail" size={30} color="#a51d31" />
          </IconContainer>
          <ContactInfo>
            <ContactInfoTextHeader>Email</ContactInfoTextHeader>
            <A style={{
              fontFamily: 'sans-serif'
            }} href={`email:${email}`}>{email}</A>
          </ContactInfo>
        </ContactInfoContainer>
      ),
    },
  ];

  // Filter out the elements that are not to be rendered.
  const validContactInfo = contactInfo.filter((info) => info.check);

  return (
    <MainContactInfoContainer>
      {validContactInfo.map((info, index) => (
        <React.Fragment key={index}>
          {info.element}
          {/* If it's not the last element, render the HorizontalRule */}
          {index !== validContactInfo.length - 1 && <HorizontalRule />}
        </React.Fragment>
      ))}
    </MainContactInfoContainer>
  );
}

const MainContactInfoContainer = styled.View`
  width: 100%;
  height: auto;
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom:10px;
`;

const ContactInfoContainer = styled.View`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
`;

const ContactText = styled.Text`
  font-family: sans-serif;
`

const IconContainer = styled.View`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ContactInfo = styled.View`
  width: 300px;
  height: auto;
`;

const ContactInfoTextHeader = styled.Text`
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  color: #a51d31;
`;

export default MainMemberContactInfo;
