import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import _ from 'lodash';
import ucdmongoapi from '../api/ucdmongoapi';

/** STATE OBJECT */
const initialState = {
  churchGroupLoading: 'idle',
  churchGroupResultMessage: '',
  churchGroupsList: [],
  selectedGroupId: 0,
  selectedGroupName: '',
  selectedGroup: [],
};

/** ASYNC ACTIONS */
export const fetchChurchGroups = createAsyncThunk(
  'ucdChurchGroup/fetchChurchGroups',
  async (churchId, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdmongoapi.post('/api/churchgroup/findbychurchid', {
      churchId,
    });
    console.log(response);
    if (200 === response.status) {
      return response.data;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const addChurchGroup = createAsyncThunk(
  'ucdChurchGroup/addChurchGroup',
  async (groupName, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdmongoapi.post('/api/churchgroup/create', {
      churchId: state.ucdUser.userChurchAppId,
      groupName,
      session_key: state.ucdSession.sessionKey,
    });
    if (200 === response.status) {
      return { groupId: response.data._id, groupName };
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const removeChurchGroup = createAsyncThunk(
  'ucdChurchGroup/removeChurchGroup',
  async ({}, { rejectWithValue, getState }) => {
    const state = getState();
    const groupsSelected = _.filter(state.ucdChurchGroup.churchGroupsList, {
      selected: true,
    });
    let groupIds = [];
    _.forEach(groupsSelected, (value) => {
      groupIds.push(value._id);
    });
    const response = await ucdmongoapi.put('/api/churchgroup/remove', {
      _id: _.join(groupIds, ','),
    });
    if (200 === response.status) {
      if (response.data.success) {
        return groupIds;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const addChurchMemberToGroup = createAsyncThunk(
  'ucdChurchGroup/addChurchMemberToGroup',
  async ({ groupId, familyId }, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdmongoapi.post('/api/churchgroup/member/add', {
      groupId,
      familyId,
    });
    if (200 === response.status) {
      return response.data;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const removeChurchMemberFromGroup = createAsyncThunk(
  'ucdChurchGroup/removeChurchMemberFromGroup',
  async ({ groupId, familyId }, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdmongoapi.put('/api/churchgroup/member/remove', {
      groupId,
      familyId,
    });
    console.log(response);
    if (200 === response.status) {
      return familyId;
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdChurchGroupSlice = createSlice({
  name: 'ucdChurchGroup',
  initialState,
  reducers: {
    setChurchGroupLoadingStatus(state, { payload }) {
      state.churchGroupLoading = payload.loadingStatus;
      state.churchGroupResultMessage = payload.resultMessage;
    },
    setSelectedChurchGroup(state, { payload }) {
      state.selectedGroupId = payload.groupId;
      let familyMembersList = [];
      const groupMembers = _.find(state.churchGroupsList, {
        _id: payload.groupId,
      });
      state.selectedGroupName = groupMembers.groupName;
      _.forEach(groupMembers.churchGroupFamilies, (value) => {
        familyMembersList.push({
          id: value._id,
          mongoId: value._id,
          name: value.name,
          email: value.email,
          members: value.members,
          isSelected: true,
        });
      });
      _.forEach(payload.allMembers, (value) => {
        const familyExistsIndex = _.findIndex(familyMembersList, {
          id: value.mongoId,
        });
        if (-1 === familyExistsIndex) {
          familyMembersList.push({
            id: value.id,
            mongoId: value.mongoId,
            name: value.name,
            email: value.email,
            members: value.members,
            isSelected: false,
          });
        }
      });
      state.selectedGroup = familyMembersList;
    },
    setGroupSelected(state, { payload }) {
      console.log('setGroupSelected', payload);
      const groupIndexToSelect = _.findIndex(state.churchGroupsList, {
        _id: payload.groupId,
      });
      try {
        state.churchGroupsList[groupIndexToSelect].selected =
          payload.isSelected;
      } catch (e) {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChurchGroups.pending, (state) => {
        state.churchGroupLoading = 'pending';
        state.churchGroupResultMessage = 'Loading Groups...';
      })
      .addCase(fetchChurchGroups.fulfilled, (state, { payload }) => {
        state.churchGroupLoading = 'idle';
        state.churchGroupResultMessage = '';
        console.log('fetchChurchGroups.fulfilled', payload);
        state.churchGroupsList = payload;
      })
      .addCase(fetchChurchGroups.rejected, (state, { payload }) => {
        state.churchGroupLoading = 'failed';
        state.churchGroupResultMessage = payload;
      })
      .addCase(addChurchGroup.pending, (state) => {
        state.churchGroupLoading = 'adding';
        state.churchGroupResultMessage = 'Adding Group...';
      })
      .addCase(addChurchGroup.fulfilled, (state, { payload }) => {
        state.churchGroupLoading = 'success';
        state.churchGroupResultMessage = 'Group Added';
        state.churchGroupsList.push({
          group_id: payload.groupId,
          group_name: payload.groupName,
          selected: false,
          people: {
            families: [],
          },
        });
      })
      .addCase(addChurchGroup.rejected, (state, { payload }) => {
        state.churchGroupLoading = 'error';
        state.churchGroupResultMessage = payload;
      })
      .addCase(removeChurchGroup.pending, (state) => {
        state.churchGroupLoading = 'removing';
        state.churchGroupResultMessage = 'Removing Group...';
      })
      .addCase(removeChurchGroup.fulfilled, (state, { payload }) => {
        state.churchGroupLoading = 'removed';
        state.churchGroupResultMessage = 'Group Removed';
        _.forEach(payload, (groupId) => {
          state.churchGroupsList = _.filter(
            state.churchGroupsList,
            (groupEntry) => groupEntry.group_id != groupId
          );
        });
      })
      .addCase(removeChurchGroup.rejected, (state, { payload }) => {
        state.churchGroupLoading = 'removalerror';
        state.churchGroupResultMessage = payload;
      })
      .addCase(addChurchMemberToGroup.pending, (state) => {
        state.churchGroupLoading = 'pending';
        state.churchGroupResultMessage = 'Adding Families Group...';
      })
      .addCase(addChurchMemberToGroup.fulfilled, (state, { payload }) => {
        state.churchGroupLoading = 'success';
        state.churchGroupResultMessage = 'Families Added';
      })
      .addCase(addChurchMemberToGroup.rejected, (state, { payload }) => {
        state.churchGroupLoading = 'failed';
        state.churchGroupResultMessage = payload;
      })
      .addCase(removeChurchMemberFromGroup.pending, (state) => {
        state.churchGroupLoading = 'pending';
        state.churchGroupResultMessage = 'Removing Families From Group...';
      })
      .addCase(removeChurchMemberFromGroup.fulfilled, (state, { payload }) => {
        state.churchGroupLoading = 'success';
        state.churchGroupResultMessage = 'Families Removed From Group';
        const selectedFamilyInSelectedGroupIndex = _.findIndex(
          state.selectedGroup,
          {
            id: payload,
          }
        );
        state.selectedGroup[
          selectedFamilyInSelectedGroupIndex
        ].isSelected = false;
        const selectedGroupIndex = _.findIndex(state.churchGroupsList, {
          group_id: state.selectedGroupId,
        });
        try {
          state.churchGroupsList[selectedGroupIndex].people.families = _.filter(
            state.churchGroupsList[selectedGroupIndex].people.families,
            (value) => value.id !== payload
          );
        } catch (e) {}
      })
      .addCase(removeChurchMemberFromGroup.rejected, (state, { payload }) => {
        state.churchGroupLoading = 'failed';
        state.churchGroupResultMessage = payload;
      });
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdChurchGroupSlice;

export const {
  setChurchGroupLoadingStatus,
  setSelectedChurchGroup,
  setGroupSelected,
} = actions;

// export the reducer
export default reducer;
