import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { setLoadedModule } from '../../reducers/ucdModuleLoaderSlice';

function NavigationItem(props) {
  const { navIcon, moduleToLoad, isActive, setActiveModule } = props;
  const dispatch = useDispatch();

  return (
    <TouchableOpacity     
      onPress={() => {
        dispatch(setLoadedModule(moduleToLoad));
        setActiveModule(moduleToLoad);  
      }}
    >
      {isActive ? <ActiveNavigationButton> 
        <NavIcon
          source={
            require(`../../../icon/${navIcon}`)
          }
        />
      </ActiveNavigationButton> : <NavigationButton>
        <NavIcon
          source={
            require(`../../../icon/${navIcon}`)
          }
        />
      </NavigationButton>}
    </TouchableOpacity>
  );
}


const NavigationButton = styled.View`
  width: 100px;
  height: 90px;
  margin-bottom: 5px;
  padding: 10px;
  box-sizing: border-box;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:'pointer';
  border-right-width: 7px;
  border-right-color: #fff;
`;

const ActiveNavigationButton = styled(NavigationButton)`
  border-right-width: 7px;
  border-right-color: #a51d31;
  background:#f1f1ef;
`;

export const NavIcon = styled.Image`
  width:45px;
  height:45px;
  resizeMode: contain;
`

export default NavigationItem;