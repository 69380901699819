import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import DirectoryArea from './directory/DirectoryArea';
import GroupsArea from './groups/GroupsArea';
import CustomButtonArea from './directory/admin-custom-buttons/CustomButtonArea';
import NotificationManagementArea from './notification-management/NotificationManagementArea';

function ModuleArea() {
  const { currentLoadedModule } = useSelector((state) => state.ucdModuleLoader);
  const { activeFeatures } = useSelector((state) => state.ucdUser);
  return (
    <ModuleAreaContainer>
      {'directory' === currentLoadedModule && activeFeatures.directory && <DirectoryArea />}
      {'groups' === currentLoadedModule && activeFeatures.directory && <GroupsArea />}
      {'admin-custom-buttons' === currentLoadedModule && activeFeatures.directory && <CustomButtonArea />}
      {'notification-management' === currentLoadedModule && activeFeatures.directory && <NotificationManagementArea />}
      {!activeFeatures.directory && (
        <NotAvailableMessage>
          Your Directory is not Available.
        </NotAvailableMessage>
      )}
    </ModuleAreaContainer>
  );
}

const ModuleAreaContainer = styled.View`
  flex: 2;
`;

const NotAvailableMessage = styled.Text`
  font-size: x-large;
  font-weight: bold;
`;

export default ModuleArea;
