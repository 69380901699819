import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { useSelector, useDispatch } from 'react-redux';
import CustomButtonRow from './CustomButtonRow';
import { findChurchCustomButtons } from '../../../reducers/ucdChurchCustomButtonSlice';

function CustomButtonArea() {

    const { userChurchAppId } = useSelector((state) => state.ucdUser);
    const { churchCustomButtons } = useSelector((state) => state.ucdChurchCustomButton); 

    const [customButtons, setCustomButtons] = useState([]);
    const dispatch = useDispatch();

    const findChurchButtonsData = {
        churchId: userChurchAppId
    };

    useEffect(() => {
        dispatch(findChurchCustomButtons(findChurchButtonsData));
    }, []);

    useEffect(() => {
        setCustomButtons(churchCustomButtons);
    }, [churchCustomButtons]);

    function refreshListCallback(){
        dispatch(findChurchCustomButtons(findChurchButtonsData));
    }

    const firstBlankButtonIndex = customButtons.findIndex(button => button._id === undefined); // Adjust the condition as needed
    console.log('firstBlankButtonIndex:' , firstBlankButtonIndex);

    return (
        <CustomButtonAreaParent
            contentContainerStyle={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {customButtons.map((customButton, index) => (
                <CustomButtonRow key={customButton._id} customButton={customButton} userChurchAppId={userChurchAppId} refreshListCallback={refreshListCallback} disabled={index > firstBlankButtonIndex && firstBlankButtonIndex !== -1}/>
            ))}
        </CustomButtonAreaParent>
    );
}

const CustomButtonAreaParent = styled(ScrollView)`
    width:100%;
    height:100%;
`;

export default CustomButtonArea;

