import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import {
  H4Text,
  CommonFormTextInput,
  CommonButton,
} from '../../styles/common-styles.js';
import { signUpUser } from '../../reducers/ucdUserSlice';

function CreateAccountForm() {
  const { userFamilyName, userEmail } = useSelector((state) => state.ucdUser);
  const dispatch = useDispatch();

  const firstNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const userNameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const emailInputRef = useRef(null);

  return (
    <CreateAccountContainer>
      <H4Text>Create Your Account</H4Text>
      <View style={{ flexDirection: 'row' }}>
        <CreateAccountInput
          autoCorrect={false}
          autoCapitalize='none'
          placeholder='FIRST NAME'
          ref={firstNameInputRef}
        />
        <CreateAccountInput
          autoCorrect={false}
          autoCapitalize='none'
          placeholder='LAST NAME'
          ref={lastNameInputRef}
          defaultValue={userFamilyName}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <CreateAccountInput
          autoCorrect={false}
          autoCapitalize='none'
          placeholder='USERNAME'
          ref={userNameInputRef}
        />
        <CreateAccountInput
          autoCorrect={false}
          autoCapitalize='none'
          placeholder='PASSWORD'
          ref={passwordInputRef}
          secureTextEntry={true}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <CreateAccountInput
          autoCorrect={false}
          autoCapitalize='none'
          placeholder='EMAIL ADDRESS'
          ref={emailInputRef}
          defaultValue={userEmail}
        />
      </View>
      <View style={{ alignItems: 'center' }}>
        <CommonButton
          onPress={() =>
            dispatch(
              signUpUser({
                userName: userNameInputRef.current.value,
                password: passwordInputRef.current.value,
                firstName: firstNameInputRef.current.value,
                lastName: lastNameInputRef.current.value,
                email: emailInputRef.current.value,
              })
            )
          }
        >
          <Text>CREATE ACCOUNT -></Text>
        </CommonButton>
      </View>
      <View>
        <Text>
          Passwords must contain at least six characters, including uppercase,
          lowercase letters and numbers.
        </Text>
        <Text>
          Your email address and password will be used to login to this in the
          future. Your password is different than your unique identifer, which
          is used to gain access in the mobile app.
        </Text>
      </View>
    </CreateAccountContainer>
  );
}

const CreateAccountContainer = styled.View`
  width: 100%;
  height: 100%;
  color: #111;
  padding-top: 15px;
  border-top: 1px solid gray;
`;

const CreateAccountInput = styled(CommonFormTextInput)`
  height: 25px;
  width: 100%;
  margin: 5px;
`;

export default CreateAccountForm;
