/** Global Configurations */
const appMode = 'live'; // either dev or live
export let apiURI = '';
export let imageHostURI = '';
export let serverImageHostURI = '';
export let mongoDBURI = '';
export let siteURL = '';
export const API_KEY = 'Bx5UvD6v0Z004il0A4Nrt2x36vD5U9o8';
export const showDebugInfo = true; // flag to show debugging information on the screen

// set values based on if the app is in dev mode or live
if ('dev' === appMode) {
  apiURI = 'https://dev.mobileadmin.ucdir.com/api';
  imageHostURI = 'https://s3-us-west-2.amazonaws.com/ucd-mobile/';
  serverImageHostURI = 'https://dev.mobileadmin.ucdir.com/images/mobile/';
  mongoDBURI = 'https://ucd-api-v21-93d454aba976.herokuapp.com';
  siteURL = 'https://directory.ucdir.com/dev';
  //mongoDBURI = 'http://10.0.0.233:5000';
} else {
  apiURI = 'https://mobileadmin.ucdir.com/apiv2';
  imageHostURI = 'https://s3-us-west-2.amazonaws.com/ucd-mobile/';
  serverImageHostURI = 'https://mobileadmin.ucdir.com/images/mobile/';
  siteURL = 'https://directory.ucdir.com';
  mongoDBURI = 'https://ucd-api-v21-prod-3b3673553abc.herokuapp.com';
  //mongoDBURI = 'http://10.0.0.233:5000';
}

export const DEFAULT_IMAGE = serverImageHostURI + 'NO-IMG.jpg';
