import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, TouchableOpacity } from 'react-native';
import Svg, { Image } from 'react-native-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import queryString from 'query-string';
import EnterAccessCodeForm from './EnterAccessCodeForm';
import LoginForm from './LoginForm';
import CreateAccountForm from './CreateAccountForm';
import CustomAlert from '../CustomAlert';
import {
  setAccessCodeValid,
  setUserLoadingStatus,
    verifyAdminAccessToken,
} from '../../reducers/ucdUserSlice';

function LoginArea(props) {
  const dispatch = useDispatch();
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const {
    accessCodeValid,
    userResultMessage,
    userLoading,
    userId,
    isChurchAdmin,
    userChurchAppId,
    userChurchName,
    userFamilyName,
    userChurchId,
    userFirstName,
    userLastName,
    userEmail,
  } = useSelector((state) => state.ucdUser);

  // check for an access token in the url when the component loads
    useEffect(() => {
        const parsedQueryString = queryString.parse(window.location.search);
        console.log('Parsed Query String', parsedQueryString);
        if (Object.keys(parsedQueryString).length > 0) {
            if (parsedQueryString.access_token !== undefined) {
                dispatch(verifyAdminAccessToken(parsedQueryString.access_token));
            }
        }
    }, []);

  // monitor for changes in the access code
  useEffect(() => {
    if (accessCodeValid) {
      // show the signup form
      setShowSignupForm(true);
    }
  }, [accessCodeValid]);

  // monitor for a user id so can transition to the directory page
  useEffect(() => {
    const checkForUser = async () => {
      console.log('User Id', userId);
      if (userId > 0) {
        // this indicates a successful login, persist the user data and go to the dashboard
        await AsyncStorage.setItem(
          'currentUserData',
          JSON.stringify({
            isChurchAdmin: isChurchAdmin,
            userId: userId,
            userChurchId: userChurchId,
            userChurchAppId: userChurchAppId,
            userChurchName: userChurchName,
            userFamilyName: userFamilyName,
            userFirstName: userFirstName,
            userLastName: userLastName,
            userEmail: userEmail,
          })
        );
        props.navigation.navigate('main');
      }
    };
    checkForUser();
  }, [userId]);

  useEffect(() => {
    if ('signup-success' === userLoading) {
      setShowSignupForm(false);
    }
  }, [userLoading]);

  return (
    <LoginContainer>
      {'pending' === userLoading && (
        <CustomAlert alertMessage={userResultMessage} />
      )}
      {'failed' === userLoading && (
        <CustomAlert
          closeAlert={() =>
            dispatch(
              setUserLoadingStatus({ loadingStatus: 'idle', resultMessage: '' })
            )
          }
          alertMessage={userResultMessage}
        />
      )}
      {'signup-success' === userLoading && (
        <CustomAlert
          closeAlert={() =>
            dispatch(
              setUserLoadingStatus({ loadingStatus: 'idle', resultMessage: '' })
            )
          }
          alertMessage={userResultMessage}
        />
      )}
      <LogoContainer>
        <Svg width='100%' height='100%' viewBox='0 0 80 80'>
          <Image
            href={require('../../../icon/ucd-logo-w400b.png')}
            width='80'
            height='80'
          />
        </Svg>
      </LogoContainer>
      <LoginFormContainer>
        {!showSignupForm && (
          <>
            <EnterAccessCodeForm />
            <LoginForm />
          </>
        )}
        {showSignupForm && <CreateAccountForm />}
      </LoginFormContainer>
    </LoginContainer>
  );
}

const LoginContainer = styled.View`
  flex: 1;
  width: 100%;
  height: 100%;
  color: #a51d31;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const LogoContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoginFormContainer = styled.View`
  flex: 1;
  height: 100%;
  width: 35%;
  padding: 25px;
  margin: 10px;
  border-radius: 2.5px;
  background-color: #f5f5f5;
  border: 1px solid #959595;
`;

export default LoginArea;
