import React from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import HeaderArea from './header/HeaderArea';
import NavigationArea from './navigation/NavigationArea';
import ModuleArea from './ModuleArea';
import InfoPopup from './InfoPopup';
import { BasicRow } from '../styles/common-styles.js';

function MainAppArea() {
  return (
    <MainAppContainer>
      <HeaderArea />
      <BasicRow>
        <InfoPopup />
        <NavigationArea />
        <ModuleArea />
      </BasicRow>
    </MainAppContainer>
  );
}

const MainAppContainer = styled.View`
  flex: 1;
`;

export default MainAppArea;
