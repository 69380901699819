import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { MaterialIcons } from '@expo/vector-icons';
import { A } from '@expo/html-elements';
import { AntDesign } from '@expo/vector-icons';
import Checkbox from '../../custom/Checkbox';
import styled from 'styled-components/native';
import _ from 'lodash';
import { StandardText } from '../../../styles/common-styles.js';
import {
  setSelectedChurchGroup,
  setGroupSelected,
} from '../../../reducers/ucdChurchGroupSlice';

function GroupListItem(props) {
  const { item, dispatch } = props;
  console.log('GroupListItem', item);
  //console.log('Group Checked', item.group_id, ':', item.selected);
  const { directoryFamilyList } = useSelector(
    (state) => state.ucdChurchDirectory
  );
  const { selectedGroupId } = useSelector((state) => state.ucdChurchGroup);

  const { isChurchAdmin } = useSelector((state) => state.ucdUser);

  const [groupEmailList, setGroupEmailList] = useState('');

  useEffect(() => {
    console.log('Church Group Families', item.churchGroupFamilies);
    if (_.size(item.churchGroupFamilies) > 0) {
      let familyEmails = [];
      _.forEach(item.churchGroupFamilies, (value) => {
        if (value.email.length > 0) {
          familyEmails.push(value.email);
        }
      });

      // convert to comma separated list
      _.join(familyEmails, ',');
      setGroupEmailList(familyEmails);
    }
  }, [item.churchGroupFamilies]);

  return (
    <GroupListItemContainer>
      <GroupNameColumn>
        <TouchableOpacity
          onPress={() =>
            dispatch(
              setSelectedChurchGroup({
                groupId: item._id,
                allMembers: directoryFamilyList,
              })
            )
          }
        >
          <StandardText>{item.groupName}</StandardText>
        </TouchableOpacity>
      </GroupNameColumn>
      {isChurchAdmin && (
        <>
          <GroupActionColumn>
            <A
              style={{
                fontFamily: 'sans-serif',
              }}
              href={`mailTo:${groupEmailList}`}
            >
              <AntDesign name='mail' size={30} color='#a51d31' />
            </A>
          </GroupActionColumn>
          <GroupActionColumn>
            <Checkbox
              isChecked={item.selected}
              checkedAction={() =>
                dispatch(
                  setGroupSelected({ groupId: item._id, isSelected: true })
                )
              }
              unCheckedAction={() =>
                dispatch(
                  setGroupSelected({ groupId: item._id, isSelected: false })
                )
              }
            />
          </GroupActionColumn>
        </>
      )}
    </GroupListItemContainer>
  );
}

const GroupListItemContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: none;
  border-top-color: lightgray;
  border-top-width: 1px;
  padding: 10px;
`;

const GroupNameColumn = styled.View``;

const GroupActionColumn = styled.View``;

export default GroupListItem;
