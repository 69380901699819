import React, { useState, useEffect } from 'react';
import { View, Text, Modal, Alert } from 'react-native';
import { A } from '@expo/html-elements';
import {
  AntDesign,
  MaterialIcons,
  FontAwesome,
  Feather,
} from '@expo/vector-icons';
import styled from 'styled-components/native';
import { useSelector, useDispatch } from 'react-redux';
import EditFamilyMember from '../../modals/EditFamilyMembers';
import AddFamilyMember from '../../modals/AddFamilyMember';
import { fetchFamiliesFromActiveDirectory } from '../../../reducers/ucdChurchDirectorySlice';
import { removeFamilyMember } from '../../../reducers/ucdFamilyMemberManagementSlice';

function FamilyMemberInfo(props) {
  const { first_name, last_name, cell, land, email, id } = props.item;
  const familyMember = {
    id: id,
    first_name: first_name,
    last_name: last_name,
    cell: cell,
    land: land,
    email: email,
  };

  const [userCanEdit, setUserCanEdit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const dispatch = useDispatch();

  const familyInfo = useSelector((state) => state.ucdFamilyMember);
  const { isChurchAdmin, userChurchId } = useSelector((state) => state.ucdUser);
  const { directoryId } = useSelector((state) => state.ucdChurchDirectory);

  useEffect(() => {
    setUserCanEdit(isChurchAdmin);
  }, [isChurchAdmin]);

  function handleRemoveMember() {
    console.log('Removing member...');
    const confirmation = window.confirm(
      `Are you sure you want to remove ${first_name} ${last_name}?`
    );

    if (confirmation) {
      dispatch(
        removeFamilyMember({
          familyMemberId: id,
          familyId: familyInfo.id,
          directoryId: directoryId,
        })
      );
      setTimeout(function () {
        dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
      }, 500);
    }
  }

  return (
    <FamilyMemberInfoContainer>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <EditFamilyMember
          familyMember={familyMember}
          closeModal={() => setModalVisible(false)}
        />
      </Modal>

      <Modal
        transparent={true}
        visible={addModalVisible}
        onRequestClose={() => {
          setAddModalVisible(false);
        }}
      >
        <AddFamilyMember closeModal={() => setAddModalVisible(false)} />
      </Modal>

      {typeof first_name !== 'undefined' ? (
        <React.Fragment>
          <IconContainer>
            <FontAwesome name='user-o' size={30} color='#a51d31' />
          </IconContainer>
          <TextContainer>
            <Text
              numberOfLines={1}
              style={{
                fontFamily: 'sans-serif',
                fontSize: 18,
                width: 200,
              }}
            >
              {first_name} {last_name}
            </Text>
            {cell.length > 0 && (
              <A
                numberOfLines={1}
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: 18,
                  marginTop: 2.5,
                  width: 185,
                }}
                href={`tel:${cell}`}
              >
                <Text
                  style={{
                    color: '#a51d31',
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginRight: 2.5,
                  }}
                >
                  c:
                </Text>
                {cell}
              </A>
            )}
            {land.length > 0 && (
              <A
                numberOfLines={1}
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: 18,
                  marginTop: 2.5,
                  width: 185,
                }}
                href={`tel:${land}`}
              >
                <Text
                  style={{
                    color: '#a51d31',
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginRight: 2.5,
                  }}
                >
                  t:
                </Text>
                {land}
              </A>
            )}
            {email.length > 0 && (
              <A
                numberOfLines={1}
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: 18,
                  marginTop: 1,
                  width: 200,
                }}
                href={`mailto:${email}`}
              >
                {email}
              </A>
            )}

            {userCanEdit && (
              <AdminFamilyMemberTools>
                <AdminEditMember
                  onPress={() => {
                    setModalVisible(true);
                  }}
                >
                  <FontAwesome name='edit' size={24} color='#a51d31' />
                  <AdminEditFamilyMemberText>Edit</AdminEditFamilyMemberText>
                </AdminEditMember>

                <AdminRemoveMember onPress={handleRemoveMember}>
                  <FontAwesome name='trash-o' size={24} color='#a51d31' />
                  <AdminEditFamilyMemberText>Remove</AdminEditFamilyMemberText>
                </AdminRemoveMember>
              </AdminFamilyMemberTools>
            )}
          </TextContainer>
        </React.Fragment>
      ) : (
        <AdminAddFamilyMember
          style={{ marginBottom: 50 }}
          onPress={() => setAddModalVisible(true)}
        >
          <IconContainer>
            <FontAwesome name='plus-square-o' size={30} color='#a51d31' />
          </IconContainer>

          <TextContainer style={{ marginTop: 5 }}>
            <AdminAddFamilyMemberText>
              Add Family Member
            </AdminAddFamilyMemberText>
          </TextContainer>
        </AdminAddFamilyMember>
      )}
    </FamilyMemberInfoContainer>
  );
}

const FamilyMemberInfoContainer = styled.View`
  flex-direction: row;
  margin-right: 50px;
  width: 270px;
  margin-top: 30px;
  min-height: 100px;
  height: auto;
`;

const IconContainer = styled.View`
  width: 45px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TextContainer = styled.View`
  margin-top: 7px;
`;

const AdminFamilyMemberTools = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 7.5px;
`;

const AdminEditMember = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AdminRemoveMember = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
`;

const AdminEditFamilyMemberText = styled.Text`
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  margin-left: 5px;
`;

const AdminAddFamilyMember = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2.5px;
`;

const AdminAddFamilyMemberText = styled.Text`
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  margin-left: 5px;
`;

export default FamilyMemberInfo;
