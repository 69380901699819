import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { H3Text, VerticalLine } from '../../styles/common-styles.js';
import { logoutUser } from '../../reducers/ucdUserSlice';
import { setShowInfoModal } from '../../reducers/ucdAppSlice';

function HeaderActions(props) {
  const dispatch = useDispatch();

  return (
    <HeaderActionsContainer>
      <TouchableOpacity
        onPress={() => {
          dispatch(logoutUser());
          props.navigation.navigate('loadScreen');
        }}
      >
        <H3Text>Logout</H3Text>
      </TouchableOpacity>
      <VerticalLine />
      <TouchableOpacity onPress={() => dispatch(setShowInfoModal(true))}>
        <Entypo name='info-with-circle' size={24} color='#a51d31' />
      </TouchableOpacity>
    </HeaderActionsContainer>
  );
}

const HeaderActionsContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: right;
`;

export default HeaderActions;
