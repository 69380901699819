import { createSlice } from '@reduxjs/toolkit';

/** STATE OBJECT */
const initialState = {
  currentLoadedModule: 'directory',
};

/** DEFINE REDUCERS */
export const ucdModuleLoaderSlice = createSlice({
  name: 'ucdModuleLoader',
  initialState,
  reducers: {
    setLoadedModule(state, { payload }) {
      state.currentLoadedModule = payload;
    },
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdModuleLoaderSlice;

export const { setLoadedModule } = actions;

// export the reducer
export default reducer;
