import React from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';

function AlertMessage(props) {
  // set the colors for the message
  let alertColors = {
    background: '',
    text: '',
  };

  switch (props.alertType) {
    case 'success':
      alertColors.background = '#e5f9e7';
      alertColors.text = '#1ebc30';
      break;
    case 'warning':
      alertColors.background = '#fff8db';
      alertColors.text = '#b58105';
      break;
    case 'error':
      alertColors.background = '#f8d7da';
      alertColors.text = '#721c24';
      break;
    default:
      alertColors.background = '#dff0ff';
      alertColors.text = '#2185d0';
      break;
  }

  return (
    <AlertContainer color={alertColors.background}>
      <AlertText color={alertColors.text}>{props.message}</AlertText>
    </AlertContainer>
  );
}

const AlertContainer = styled(View)`
  background-color: ${({ color }) => color};
  padding: 10px;
  border-radius: 4px;
`;

const AlertText = styled(Text)`
  color: ${({ color }) => color};
  font-size: 16px;
`;

export default AlertMessage;
