import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import HeaderTitle from './HeaderTitle';
import HeaderActions from './HeaderActions';

function HeaderArea() {
  return (
    <HeaderContainer>
      <HeaderTitle />
      <HeaderActions />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.View`
  background-color: #fff;
  padding: 5px;
  color: #000000;
  border-bottom-color: solid;
  border-bottom-width: 1px;
  font-size: 12px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction: row;
  height:61px;
`;

export default HeaderArea;
