import React, { useState, useEffect } from 'react';
import { View, Text, Switch, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { StandardText } from '../../../styles/common-styles.js';
import {
  addChurchMemberToGroup,
  removeChurchMemberFromGroup,
  setSelectedChurchGroup,
  fetchChurchGroups,
  setGroupSelected,
} from '../../../reducers/ucdChurchGroupSlice';
import { set } from 'lodash';

function GroupMembersListItem(props) {
  const { item, dispatch, groupId } = props;

  const { isChurchAdmin, userChurchAppId } = useSelector((state) => state.ucdUser);
  const { directoryFamilyList } = useSelector(
    (state) => state.ucdChurchDirectory
  );

  const [RowActive, setRowActive] = useState(GroupMembersListItemContainerActive);
  const [isSwitchEnabled, setSwitchEnabled] = useState(item.isSelected);

  const groupMemberSwitchToggled = async (value) => {
    setSwitchEnabled(value);

    setRowActive(GroupMembersListItemContainerDisabled);

    try {
      if (value) {
        await dispatch(addChurchMemberToGroup({ groupId: groupId, familyId: item.mongoId }));
      } else {
        await dispatch(removeChurchMemberFromGroup({ groupId: groupId, familyId: item.mongoId }));
      }

      await dispatch(fetchChurchGroups(userChurchAppId));

      dispatch(setSelectedChurchGroup({
        groupId: groupId,
        allMembers: directoryFamilyList,
      }));

      setRowActive(GroupMembersListItemContainerActive);
    } catch (error) {
      // Revert the switch state upon failure
      setSwitchEnabled(!value);
      setRowActive(GroupMembersListItemContainerActive);
    }
  };

  useEffect(() => {
    setSwitchEnabled(item.isSelected);
  }, [item.isSelected]);

  return (

    <RowActive>

      {RowActive === GroupMembersListItemContainerDisabled && (
        <RowOverlayParent>
          <RowOverlay animating={item.isLoading} size='small' color='#a51d31' />
        </RowOverlayParent>
      )}

        <FamilyNameContainer>
          <FamilyName>{item.name}</FamilyName>
          {item.members !== null && item.members.length > 0 ? <Divider></Divider> : null}
          { 
            item.members !== null 
            ? item.members.map((member, index, array) => (
                <FamilyMember key={member.id}> 
                   {member.first || member.first_name}
                  {index < array.length - 1 ? ', ' : ''}
                </FamilyMember>
              ))
            : null
          }
        </FamilyNameContainer>

      {isChurchAdmin && (
        <Switch
          value={isSwitchEnabled}
          onValueChange={groupMemberSwitchToggled}
        />
      )}
    </RowActive>
  );
}

const GroupMembersListItemContainerActive = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: none;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  padding: 10px;
`;

const GroupMembersListItemContainerDisabled = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-basis: none;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  padding: 10px;
`;

const FamilyNameContainer = styled.View`
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
`

const FamilyName = styled(StandardText)`
  font-size: 16px;
  font-weight: semibold;
`;

const FamilyMember = styled(StandardText)`
  font-size: 14px;
  font-weight: normal;
`;

const Divider = styled.View`
  width: 1px;
  height: 100%;
  background-color: #a51d31;
  margin-left: 10px;
  margin-right: 10px;
`;

const RowOverlayParent = styled.View`
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  background: rgba(255,255,255,0.5);
  display:flex;
  justify-content:center;
  align-items:center;
  z-index: 99;
`;

const RowOverlay = styled.ActivityIndicator`

`;


export default GroupMembersListItem;
