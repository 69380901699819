import React from 'react';
import { View, ActivityIndicator, Text } from 'react-native';
import styled from 'styled-components/native';

function WaitMessage(props) {
  return (
    <WaitContainer>
      <WaitCircle size='large' color='#0000ff' />
      <WaitText>{props.message}</WaitText>
    </WaitContainer>
  );
}

const WaitContainer = styled(View)`
  align-items: center;
  justify-content: center;
`;

const WaitCircle = styled(ActivityIndicator)`
  margin-bottom: 10px;
`;

const WaitText = styled(Text)`
  font-size: 16px;
`;

export default WaitMessage;
