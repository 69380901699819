import React, { useState, useEffect } from 'react';
import { Pressable, Text, View } from 'react-native';
import styled from 'styled-components/native';
import { Ionicons } from '@expo/vector-icons';

function Checkbox(props) {
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    if (checked !== null) {
      if (checked) {
        props.checkedAction();
        return;
      }

      props.unCheckedAction();
    }
  }, [checked]);

  useEffect(() => {
    setChecked(props.isChecked);
  }, [props.isChecked]);

  if (checked) {
    return (
      <CheckboxChecked onPress={() => setChecked(false)}>
        <Ionicons name='checkmark' size={24} color='white' />
      </CheckboxChecked>
    );
  }

  return <CheckboxBase onPress={() => setChecked(true)} />;
}

const CheckboxBase = styled.Pressable`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 2px;
  border-color: black;
  background-color: transparent;
`;

const CheckboxChecked = styled(CheckboxBase)`
  background-color: black;
`;

export default Checkbox;
