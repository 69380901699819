import React, { useState, useEffect } from 'react';
import { View, Text, Image, Modal } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { imageHostURI, serverImageHostURI } from '../../../config.js';
import { FontAwesome5 } from '@expo/vector-icons';
import UploadFamilyImage from '../../modals/EditFamilyImage.js';

function FamilyDetailsPhoto(props) {
  const { userChurchAppId } = useSelector((state) => state.ucdUser);
  const { imageName } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);

  const { isChurchAdmin } = useSelector((state) => state.ucdUser);

  useEffect(() => {
    setUserCanEdit(isChurchAdmin);
  }, [isChurchAdmin]);

  return (
    <MemberPhotoParent>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <UploadFamilyImage
          closeModal={() => setModalVisible(false)}
          initialImage={`${serverImageHostURI}${userChurchAppId}/${imageName}`}
        />
      </Modal>

      <MemberPhotoCont
        source={{
          uri: `${serverImageHostURI}${userChurchAppId}/${imageName}?time=${new Date().getTime()}`,
        }}
      />

      {userCanEdit && (
        <AdminTool
          onPress={() => {
            setModalVisible(true);
          }}
        >
          <FontAwesome5 name='pencil-alt' size={24} color='white' />
        </AdminTool>
      )}
    </MemberPhotoParent>
  );
}

export const MemberPhotoParent = styled.View``;

export const MemberPhotoCont = styled.Image`
  height: 400px;
  width: 275px;
  resizemode: cover;
`;

export const AdminTool = styled.TouchableOpacity`
  position: absolute;
  top: -13.5px;
  right: -13.5px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #a51d31;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FamilyDetailsPhoto;
