import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { AntDesign } from '@expo/vector-icons';
import GroupMembersList from './GroupMembersList';
import { H2Text } from '../../../styles/common-styles.js';

function GroupMembersArea() {
  const { selectedGroupName } = useSelector((state) => state.ucdChurchGroup);
  return (
    <GroupMembersAreaContainer>
      <HeaderContainer>
        <H2Text>{selectedGroupName} Group Members</H2Text>
      </HeaderContainer>
      <GroupMembersListContainer>
        <GroupMembersList />
      </GroupMembersListContainer>
    </GroupMembersAreaContainer>
  );
}

const GroupMembersAreaContainer = styled.View`
  flex: 1;
`;
const HeaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin-top:25px;
  margin-bottom:-10px;
`;

const GroupMembersListContainer = styled.View`
  fled: 1;
`;

export default GroupMembersArea;
