import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Modal, TextInput, Button } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import WaitMessage from '../../custom/WaitMessage';
import AlertMessage from '../../custom/AlertMessage';
import {
  CommonButton,
  CommonFormTextInput,
  CommonButtonText,
} from '../../../styles/common-styles.js';

function AddGroupModal(props) {
  const groupNameRef = useRef(null);

  const { churchGroupLoading, churchGroupResultMessage } = useSelector(
    (state) => state.ucdChurchGroup
  );

  return (
    <Modal
      visible={props.showModal}
      animationType='slide'
      onRequestClose={() => props.setModalVisible(false)}
      transparent
    >
      <ModalContainer>
        <ModalContent>
          {'adding' === churchGroupLoading && (
            <WaitMessage message={churchGroupResultMessage} />
          )}
          {('success' === churchGroupLoading ||
            'error' === churchGroupLoading) && (
            <AlertMessage
              message={churchGroupResultMessage}
              alertType={churchGroupLoading}
            />
          )}
          {churchGroupLoading !== 'adding' && (
            <>
              <Text>Enter the Name for the Group</Text>
              <CommonFormTextInput
                autoCorrect={false}
                autoCapitalize='none'
                ref={groupNameRef}
                placeholder='Group Name'
              />
              <ButtonContainer>
                <CommonButton
                  onPress={() => props.saveGroup(groupNameRef.current.value)}
                >
                  <CommonButtonText>Add Group</CommonButtonText>
                </CommonButton>
                <CommonButton
                  onPress={() => {
                    props.setModalVisible(false);
                    props.setGroupStateStatus();
                  }}
                >
                  <CommonButtonText>Close</CommonButtonText>
                </CommonButton>
              </ButtonContainer>
            </>
          )}
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
}

const ModalContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.View`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 20%;
  height:200px;
  align-items: center;
`;

const ButtonContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default AddGroupModal;
