import styled from "styled-components/native";
import React from "react";
import { useSelector } from 'react-redux';

export function AudienceSelect(props) {
    const { activeFeatures } = useSelector((state) => state.ucdUser);
    return <>
        <CreateNotifHeaderText>Send Notification</CreateNotifHeaderText>
        <AudienceSelectionHeader>Who will receive this notification?</AudienceSelectionHeader>
        <AudienceSelectionCont>
            {props.selectedAudience === "Everyone" ? (
                <AudienceSelectionSelected onPress={props.onPress}>
                    <AudienceSelectionText>Everyone</AudienceSelectionText>
                    <AudienceImg source={require("../../../icon/group.png")}/>
                </AudienceSelectionSelected>
            ) : (
                <AudienceSelection onPress={props.onPress}>
                    <AudienceSelectionText>Everyone</AudienceSelectionText>
                    <AudienceImg source={require("../../../icon/group.png")}/>
                </AudienceSelection>
            )}
            { activeFeatures.groups &&
                (props.selectedAudience === "Groups" ? (
                    <AudienceSelectionSelected onPress={props.onPress1}>
                        <AudienceSelectionText>Groups</AudienceSelectionText>
                        <AudienceImg source={require("../../../icon/member.png")}/>
                    </AudienceSelectionSelected>
                ) : (
                    <AudienceSelection onPress={props.onPress1}>
                        <AudienceSelectionText>Groups</AudienceSelectionText>
                        <AudienceImg source={require("../../../icon/member.png")}/>
                    </AudienceSelection>
                ))
            }
        </AudienceSelectionCont>
    </>;
}

const CreateNotifHeaderText = styled.Text`
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
`;
const AudienceSelectionCont = styled.View`
  margin-top: 10px;
  width: 225px;
  height: 102px;
  flex-direction: row;
`
const AudienceSelectionHeader = styled.Text`
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
  margin-top: 20px;
`;
const AudienceSelection = styled.TouchableOpacity`
  width: 100px;
  height: 100px;
  border: 2px solid lightgray;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: white;
`
const AudienceSelectionSelected = styled(AudienceSelection)`
  border: 2px solid #a51d31;
`
const AudienceSelectionText = styled.Text`
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
`;
const AudienceImg = styled.Image`
  width: 50px;
  height: 40px;
  margin-top: 10px;
  resizeMode: contain;
`;