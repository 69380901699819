import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import _ from 'lodash';
import SearchBox from '../../custom/SearchBox';
import GroupMembersListItem from './GroupMembersListItem';
import { H2Text } from '../../../styles/common-styles.js';

function GroupMembersList() {
  const dispatch = useDispatch();
  const { selectedGroup, selectedGroupId } = useSelector(
    (state) => state.ucdChurchGroup
  );
  const { isChurchAdmin } = useSelector((state) => state.ucdUser);

  const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);

  const renderItem = ({ item }) => {
    return (
      <GroupMembersListItem
        item={item}
        dispatch={dispatch}
        groupId={selectedGroupId}
      />
    );
  };

  const searchGroupMembers = (text) => {
    console.log('Search Text', text);
    console.log('Current Group Members', currentGroupMembers);
    const filteredMembers = _.filter(currentGroupMembers, (value) => {
      return value.name.toLowerCase().includes(text.toLowerCase());
    });
    console.log('Filtered', filteredMembers);
    setFilteredGroupMembers(filteredMembers);
  };

  const debouncedSearchGroupMembers = _.debounce(searchGroupMembers, 150);

  useEffect(() => {
    if (_.size(selectedGroup) > 0) {
      let groupMembers = selectedGroup;
      if (!isChurchAdmin) {
        // only show the selected members
        groupMembers = _.filter(selectedGroup, { isSelected: true });
      }
      setCurrentGroupMembers(groupMembers);
      setFilteredGroupMembers(groupMembers);
    }
  }, [selectedGroup]);

  return (
    <>
    {selectedGroup.length !== 0 && (
    <React.Fragment>
    <SearchContainer>
      <SearchText>Members</SearchText>
      <SearchBarContainer>
        <SearchIcon
          source={{
            uri: 'https://directory.ucdir.com/static/media/mag.bc74e481.png'
          }}
        />
        <SearchBar
          style={{borderWidth: 0, borderStyle:'none', outlineStyle: 'none'}}
          placeholderTextColor={'gray'}
          autoCorrect={false}
          autoCapitalize='none'
          onChangeText={(text) => debouncedSearchGroupMembers(text)} // Use debounced function here
          placeholder='Search'
        />
      </SearchBarContainer>
    </SearchContainer>
      <FlatList
        data={filteredGroupMembers}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        numColumns={1}
      />
      </React.Fragment>
    )}
    </>
  );
}

const SearchContainer = styled.View`
  width:100%;
  height:80px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left:20px;
`;

const SearchText = styled(H2Text)`
  margin-right: 15px;
  font-family:sans-serif;
  font-size:18px;
`;

const SearchBarContainer = styled.View`
  height: 35px;
  width: 250px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background:#fff;
`;

const SearchBar = styled.TextInput`
  height: 35px;
  width: 225px;
  font-size: 0.9em;
  background:#fff;
  margin-left:6px;
  border-width:0;
  padding-left:1px;
`;

export const SearchIcon = styled.Image`
  width:15px;
  height:15px;
  margin-left:12px;
  resizeMode:stretch;
`



export default GroupMembersList;
