import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import {
  H4Text,
  CommonFormTextInput,
  CommonButton,
  CommonButtonText,
} from '../../styles/common-styles.js';
import { loginUser } from '../../reducers/ucdUserSlice';

function LoginForm() {
  const dispatch = useDispatch();
  const userNameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  return (
    <LoginOrCreateUserContainer>
      <LoginUserContainer>
        <H4Text>
          If you already have an account, please enter your username & password
          to login.
        </H4Text>
        <LoginInputsContainer>
          <View>
            <LoginInputsInput
              autoCorrect={false}
              autoCapitalize='none'
              ref={userNameInputRef}
              placeholder='USERNAME'
            />
          </View>
          <View>
            <LoginInputsInput
              autoCorrect={false}
              autoCapitalize='none'
              ref={passwordInputRef}
              placeholder='PASSWORD'
              secureTextEntry={true}
            />
            <CommonButton
              onPress={() =>
                dispatch(
                  loginUser({
                    userName: userNameInputRef.current.value,
                    password: passwordInputRef.current.value,
                  })
                )
              }
            >
              <CommonButtonText>Login</CommonButtonText>
            </CommonButton>
          </View>
        </LoginInputsContainer>
      </LoginUserContainer>
    </LoginOrCreateUserContainer>
  );
}

const LoginSecurityContainer = styled.View`
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
  color: #111;
  height: 25%;
  align-items: center;
`;

const LoginOrCreateUserContainer = styled.View`
  flex: 1;
  width: 100%;
  height: 75%;
  padding-bottom: 15px;
  color: #111;
`;

const LoginUserContainer = styled.View`
  width: 100%;
  height: 100%;
  color: #111;
  padding-top: 15px;
  border-top: 1px solid gray;
  align-items: center;
`;

const LoginInputsContainer = styled.View`
  display: inline-block;
`;

const LoginInputsInput = styled(CommonFormTextInput)`
  margin: 5px;
`;

export default LoginForm;
