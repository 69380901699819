import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'react-native';
import { updateFamilyInfo } from '../../reducers/ucdUpdateFamilyInfoSlice';
import {
  addNewFamily,
  setFamilyMemberLoadingStatus,
} from '../../reducers/ucdFamilyMemberSlice';
import { fetchFamiliesFromActiveDirectory } from '../../reducers/ucdChurchDirectorySlice';
import styled from 'styled-components/native';
import { Feather } from '@expo/vector-icons';
import validator from 'validator';

function EditFamilyInformation({ closeModal }) {
  const dispatch = useDispatch();

  const familyInfo = useSelector((state) => state.ucdFamilyMember);
  const { userChurchId } = useSelector((state) => state.ucdUser);

  const [formData, setFormData] = useState({
    id: familyInfo.id,
    lastName: familyInfo.familyName,
    address: familyInfo.address,
    address2: '',
    city: familyInfo.city,
    state: familyInfo.state,
    zip: familyInfo.postalCode,
    phone: familyInfo.phone,
    emailAddress: familyInfo.email,
  });

  useEffect(() => {
    switch (familyInfo.familyMemberLoading) {
      case 'new-family-added':
        dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
        closeModal();
        dispatch(
          setFamilyMemberLoadingStatus({
            loadingStatus: 'idle',
            resultMessage: '',
          })
        );
        break;
      case 'failed':
        alert(familyInfo.familyMemberResultMessage);
        break;
    }
  }, [familyInfo.familyMemberLoading]);

  function handleChange(name, value) {
    // Updated to work with React Native TextInput
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function validateUpdate() {
    // last name is the only thing required

    const hasLastName = validator.isLength(formData.lastName, {
      min: 1,
    });

    let validationErrorMessage = '';

    if (!hasLastName) {
      // form invalid
      validationErrorMessage += !hasLastName ? 'Last Name is required.\n' : '';
    }

    if (validator.isLength(formData.emailAddress, { min: 1 })) {
      if (!validator.isEmail(formData.emailAddress)) {
        // email is invalid
        validationErrorMessage += 'Email Address is not valid.';
      }
    }

    if (validationErrorMessage.length > 0) {
      alert(validationErrorMessage);
      return;
    }

    // submit the form if all good
    if (formData.id > 0) {
      submitUpdate();
    } else {
      submitNewFamily();
    }
  }

  function submitUpdate() {
    dispatch(updateFamilyInfo(formData))
      .then(() => {
        dispatch(fetchFamiliesFromActiveDirectory(userChurchId));
      })
      .then(() => {
        closeModal();
      });
  }

  function submitNewFamily() {
    dispatch(addNewFamily(formData));
  }

  return (
    <AdminModalParent>
      <AdminModalContainer>
        <HeaderContainer>
          <AdminModalHeaderText>
            {formData.id > 0 ? 'Update' : 'New'} Family Information
          </AdminModalHeaderText>
          <CloseButton onPress={closeModal}>
            <Feather name='x-circle' size={38} color='#a51d31' />
          </CloseButton>{' '}
          {/* Close Button */}
        </HeaderContainer>

        <Form>
          <FormItem>
            <FormText>Last Name:*</FormText>
            <FormInput
              value={formData.lastName}
              onChangeText={(value) => handleChange('lastName', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Address:</FormText>
            <FormInput
              value={formData.address}
              onChangeText={(value) => handleChange('address', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>City:</FormText>
            <FormInput
              value={formData.city}
              onChangeText={(value) => handleChange('city', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>State:</FormText>
            <FormInput
              value={formData.state}
              onChangeText={(value) => handleChange('state', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Zip Code:</FormText>
            <FormInput
              value={formData.zip}
              onChangeText={(value) => handleChange('zip', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Phone:</FormText>
            <FormInput
              value={formData.phone}
              onChangeText={(value) => handleChange('phone', value)}
            />
          </FormItem>

          <FormItem>
            <FormText>Email Address:</FormText>
            <FormInput
              value={formData.emailAddress}
              onChangeText={(value) => handleChange('emailAddress', value)}
            />
          </FormItem>

          <FormSubmit onPress={validateUpdate}>
            <FormSubmitText>Submit</FormSubmitText>
          </FormSubmit>
        </Form>
      </AdminModalContainer>
    </AdminModalParent>
  );
}

export const AdminModalParent = styled.View`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminModalContainer = styled.View`
  padding: 20px;
  height: auto;
  width: auto;
  box-shadow: 0px 0px 5px black;
  background: #f2f2f2;
`;

export const AdminModalHeaderText = styled.Text`
  font-family: sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const Form = styled.View``;

export const FormItem = styled.View`
  margin-top: 15px;
`;

export const FormText = styled.Text`
  font-size: 16px;
  font-family: sans-serif;
`;

export const FormInput = styled.TextInput`
  width: 200px;
  height: 25px;
  background: white;
  padding: 2.5px;
  font-size: 14px;
  font-family: sans-serif;
  margin-top: 5px;
`;

export const FormSubmit = styled.TouchableOpacity`
  width: 100px;
  height: 35px;
  background: #a51d31;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FormSubmitText = styled.Text`
  font-family: sans-serif;
  font-size: 16px;
  color: white;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled.Text`
  font-size: 24px;
  cursor: pointer;
  margin-left: 15px;
`;

export default EditFamilyInformation;
