import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI } from '../config.js';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  familyMemberLoading: 'idle',
  familyMemberResultMessage: '',
};

/** ASYNC ACTIONS */
export const addFamilyMember = createAsyncThunk(
  'FamilyMemberManagement/addFamilyMember',
  async (familyMember, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/add_family_member', {
      ...familyMember,
      sessionKey: state.ucdSession.sessionKey,
      directoryId: state.ucdChurchDirectory.directoryId,
    });

    if (200 === response.status) {
      if (response.data.success) {
        console.log('Family Member Added');
        return response.data.msg;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const updateFamilyMemberInfo = createAsyncThunk(
  'FamilyMemberManagement/updateFamilyMemberInfo',
  async (familyMember, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/update_family_member_info', {
      ...familyMember,
      sessionKey: state.ucdSession.sessionKey,
      directoryId: state.ucdChurchDirectory.directoryId,
    });

    if (200 === response.status) {
      if (response.data.success) {
        console.log('Family Member Info Updated');
        return response.data.msg;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

export const removeFamilyMember = createAsyncThunk(
  'FamilyMemberManagement/removeFamilyMember',
  async (data, { rejectWithValue, getState }) => {
    const state = getState();
    const response = await ucdapi.post('/remove_family_member', {
      ...data,
      sessionKey: state.ucdSession.sessionKey,
    });

    if (200 === response.status) {
      if (response.data.success) {
        console.log('Family Member Removed');
        return response.data.msg;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const FamilyMemberManagementSlice = createSlice({
  name: 'FamilyMemberManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addFamilyMember.pending, (state) => {
        state.familyMemberLoading = 'pending';
        state.familyMemberResultMessage = 'Adding Family Member...';
      })
      .addCase(addFamilyMember.fulfilled, (state, { payload }) => {
        state.familyMemberLoading = 'idle';
        state.familyMemberResultMessage = payload;
      })
      .addCase(addFamilyMember.rejected, (state, { payload }) => {
        state.familyMemberLoading = 'failed';
        state.familyMemberResultMessage = payload;
      })
      .addCase(updateFamilyMemberInfo.pending, (state) => {
        state.familyMemberLoading = 'pending';
        state.familyMemberResultMessage = 'Updating Family Member Info...';
      })
      .addCase(updateFamilyMemberInfo.fulfilled, (state, { payload }) => {
        state.familyMemberLoading = 'idle';
        state.familyMemberResultMessage = payload;
      })
      .addCase(updateFamilyMemberInfo.rejected, (state, { payload }) => {
        state.familyMemberLoading = 'failed';
        state.familyMemberResultMessage = payload;
      })
      .addCase(removeFamilyMember.pending, (state) => {
        state.familyMemberLoading = 'pending';
        state.familyMemberResultMessage = 'Removing Family Member...';
      })
      .addCase(removeFamilyMember.fulfilled, (state, { payload }) => {
        state.familyMemberLoading = 'idle';
        state.familyMemberResultMessage = payload;
      })
      .addCase(removeFamilyMember.rejected, (state, { payload }) => {
        state.familyMemberLoading = 'failed';
        state.familyMemberResultMessage = payload;
      });
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = FamilyMemberManagementSlice;

// export the reducer
export default reducer;
