import React from 'react';
import { View, Text, Modal, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Svg, { Image } from 'react-native-svg';
import { Ionicons } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { setShowInfoModal } from '../reducers/ucdAppSlice';

function InfoPopup() {
  const dispatch = useDispatch();
  const { showInfoModal } = useSelector((state) => state.ucdApp);

  return (
    <Modal
      visible={showInfoModal}
      animationType='slide'
      onRequestClose={() => dispatch(setShowInfoModal(false))}
      transparent
    >
      <InfoContainer>
        <CloseButton onPress={() => dispatch(setShowInfoModal(false))}>
          <CloseIcon name='ios-close' />
        </CloseButton>
        <Svg width='100%' height='100%' viewBox='0 0 200 200'>
          <Image
            href={require('../../icon/info-content.png')}
            width='200'
            height='200'
          />
        </Svg>
      </InfoContainer>
    </Modal>
  );
}

const InfoContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

const CloseButton = styled(TouchableOpacity)`
  position: absolute;
  top: 100px;
  right: 100px;
`;

const CloseIcon = styled(Ionicons)`
  font-size: 40px;
  color: #a51d31;
`;

export default InfoPopup;
