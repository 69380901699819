import React from "react";
import styled from "styled-components/native";

export function GroupList(props) {
    return (
        <>
            {props.selectedAudience === "Groups" && (
                <GroupsListCont>
                    {Object.keys(props.churchGroupsList).length !== 0 ? (
                        <>
                            <GroupsListHeader>Please select the groups that will receive this notification.</GroupsListHeader>
                            <GroupsList>
                                {props.churchGroupsList.map(props.prop2)}
                            </GroupsList>
                        </>
                    ) : (
                        <GroupsListHeader>You have not created any groups yet. Please navigate to the Group management tab and create one.</GroupsListHeader>
                    )}
                </GroupsListCont>
            )}
        </>
    );
}
const GroupsListCont = styled.View`
  margin-top: 20px;
  width: 350px;
  height: 200px;
`;
const GroupsListHeader = styled.Text`
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  color: black;
`;
const GroupsList = styled.ScrollView`
  width: 100%;
  height: 100%;
  border: 2px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  background: white;
`;