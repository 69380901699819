import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

/** STATE OBJECT */
const initialState = {
  showInfoModal: false,
};

/** DEFINE REDUCERS */
export const ucdAppSlice = createSlice({
  name: 'ucdApp',
  initialState,
  reducers: {
    setShowInfoModal(state, { payload }) {
      state.showInfoModal = payload;
    },
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdAppSlice;

export const { setShowInfoModal } = actions;

// export the reducer
export default reducer;
