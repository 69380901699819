import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiURI, API_KEY } from '../config.js';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  sessionKey: '',
  authLoading: 'idle', // other values are pending, succeeded and failed
  authResultMessage: '',
};

/** ASYNC ACTIONS */
export const startSession = createAsyncThunk(
  'ucdSession/startSession',
  async ({}, { rejectWithValue }) => {
    console.log('Starting New Session');
    const response = await ucdapi.post('/start_session', { api_key: API_KEY });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      if (response.data.success) {
        // return the session key
        return response.data.session_key;
      } else {
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

export const verifySession = createAsyncThunk(
  'ucdSession/verifySession',
  async (sessionKey, { rejectWithValue }) => {
    const response = await ucdapi.post('/verify_session', {
      session_key: sessionKey,
    });

    // did we get a successful response code?
    if (200 === response.status) {
      // verify that the endpoint returned a success message
      //console.log(response.data);
      if (response.data.success) {
        // return a success
        return sessionKey;
      } else {
        console.log(response.data.msg);
        // something went wrong, so return an error
        return rejectWithValue(response.data.msg);
      }
    } else {
      // return the status text from the response
      return rejectWithValue(response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdSessionSlice = createSlice({
  name: 'ucdSession',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startSession.pending, (state) => {
        state.authLoading = 'pending';
        state.authResultMessage = 'Starting new session...';
      })
      .addCase(startSession.fulfilled, (state, { payload }) => {
        state.authLoading = 'idle';
        state.authResultMessage = '';
        state.sessionKey = payload;
      })
      .addCase(startSession.rejected, (state, { payload }) => {
        state.authLoading = 'failed';
        state.authResultMessage = payload;
        state.sessionKey = '';
      })
      .addCase(verifySession.pending, (state) => {
        state.authLoading = 'pending';
        state.authResultMessage = 'Starting new session...';
      })
      .addCase(verifySession.fulfilled, (state, { payload }) => {
        state.authLoading = 'idle';
        state.authResultMessage = '';
        state.sessionKey = payload;
      })
      .addCase(verifySession.rejected, (state, { payload }) => {
        console.log('Rejected', payload);
        state.authLoading = 'failed';
        state.authResultMessage = payload;
        state.sessionKey = '';
      });
  },
});

// extract the action creators object and the reducer
const { actions, reducer } = ucdSessionSlice;

// export the reducer
export default reducer;
