import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, Text } from 'react-native';
import { startSession, verifySession } from '../reducers/ucdSessionSlice';
import {
  setUserAndCheckAppFeaturesActive,
  resetUser,
} from '../reducers/ucdUserSlice';
import { siteURL } from '../config.js';

function LoadScreen(props) {
  const dispatch = useDispatch();
  const { sessionKey, authLoading } = useSelector((state) => state.ucdSession);
  const { loggingOut, activeFeatures } = useSelector((state) => state.ucdUser);

  useEffect(() => {
    const verifyOrStartSession = async () => {
      const savedSessionKey = await AsyncStorage.getItem('savedSessionKey');
      console.log('Auth Loading', authLoading);
      if (authLoading !== 'pending' && savedSessionKey !== null) {
        // verify the saved key
        dispatch(verifySession(savedSessionKey));
      } else {
        // start a brand new session
        dispatch(startSession({}));
      }
    };
    verifyOrStartSession();
  }, []);

  // monitor the session key and move to the login screen when it changes
  useEffect(() => {
    const checkSessionKey = async () => {
      if ('idle' === authLoading && sessionKey && sessionKey.length > 0) {
        // save the current session key in async storage
        await AsyncStorage.setItem('savedSessionKey', sessionKey);
        // check asyncstorage to see if login information has been saved
        const savedUserJsonData = await AsyncStorage.getItem('currentUserData');
        const savedUserData =
          savedUserJsonData !== null ? JSON.parse(savedUserJsonData) : null;
        if (savedUserData === null) {
          // go to the login screen
          props.navigation.navigate('login');
          return;
        }

        // take the user info and update the user state object, then navigate to the dashboard
        dispatch(setUserAndCheckAppFeaturesActive(savedUserData));
        props.navigation.navigate('main');
      } else if ('failed' === authLoading) {
        // there is not a valid session key, need to generate a new session
        dispatch(startSession({}));
      }
    };
    checkSessionKey();
  }, [sessionKey, authLoading]);

  useEffect(() => {
    const clearUserAndSession = async () => {
      if (loggingOut) {
        // clear out storage and send the user to the login screen
        await AsyncStorage.removeItem('savedSessionKey');
        await AsyncStorage.removeItem('currentUserData');
        dispatch(resetUser());
        window.location.replace(siteURL);
        //props.navigation.navigate('login');
      }
    };
    clearUserAndSession();
  }, [loggingOut]);

  return (
    <View>
      <Text>Loading...</Text>
    </View>
  );
}

export default LoadScreen;
