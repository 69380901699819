import React, {useState} from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import CreateNotification from './CreateNotification';
import ViewPreviousNotifications from './ViewPreviousNotifications';
import {findNotificationsByChurchId} from "../../reducers/ucdPushNotificationsSlice"
import {useDispatch, useSelector} from "react-redux";

function NotificationManagementArea() {
    const { userChurchAppId } = useSelector((state) => state.ucdUser);

    const dispatch = useDispatch();

    const [localPrevNotifs, setLocalPrevNotifs] = useState([]);
    const fetchData = async () => {
        try {
            const fetchedData = await dispatch(findNotificationsByChurchId({ churchId: userChurchAppId }));
            setLocalPrevNotifs(fetchedData.payload);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

  return (
    <NotificationManagementParent>
      <NotificationManagementLeft>
        <CreateNotification fetchData={fetchData} />
      </NotificationManagementLeft>
      <NotificationManagementRight horizontal={true}>
        <ViewPreviousNotifications fetchData={fetchData} localPrevNotifs={localPrevNotifs}/>
      </NotificationManagementRight>
    </NotificationManagementParent>
  );
}

const NotificationManagementParent = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

const NotificationManagementLeft = styled.View`
    width:15%;
    min-width: 400px;
    max-width:400px;
    height: 100%;
`;

const NotificationManagementRight = styled.View`
    flex: 1;
    height: 100%;
    background-color: white;
`;

export default NotificationManagementArea;
