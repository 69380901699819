import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import ucdmongoapi from '../api/ucdmongoapi';

// Initial state of the Push Notifications
const initialState = {
    loading: 'idle',
    messages: [],
    churchMainTopicId: '',
    previousNotifications: [],
    totalFamilies: 0,
    totalDevices: 0,
    status: '',
    lastMessageStatus: ''
};

// Async action to send notifications to groups
export const sendNotificationToGroups = createAsyncThunk(
    'ucdPushNotifications/sendToGroups',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ucdmongoapi.post('/api/pushnotifications/messages/sendtogroups', payload);
            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.statusText);
            }
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

// Async action to send notifications to entire church
export const sendNotificationToEntireChurch = createAsyncThunk(
    'ucdPushNotifications/sendToEntireChurch',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ucdmongoapi.post('/api/pushnotifications/messages/sendtoentirechurch', payload);
            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.statusText);
            }
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

export const findChurchMainTopic = createAsyncThunk(
    'ucdPushNotifications/findChurchMainTopic',
    async (churchId, { rejectWithValue }) => {
        try {
            const response = await ucdmongoapi.post('/api/pushnotifications/topics/findchurchmaintopic', {
                churchId
            });
            if (response.status === 200) {
                console.log(response);
                return response.data;
            } else {
                return rejectWithValue(response.statusText);
            }
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

export const preSendNotification = createAsyncThunk(
    'ucdPushNotifications/preSend',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ucdmongoapi.post('/api/pushnotifications/messages/presend', payload);
            if (response.status === 200) {
                console.log(response);
                return response.data;
            } else {
                return rejectWithValue(response.statusText);
            }
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);

export const findNotificationsByChurchId = createAsyncThunk(
    'ucdPushNotifications/findNotificationsByChurchId',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ucdmongoapi.post('/api/pushnotifications/messages/findbychurchid', payload);
            if (response.status === 200) {
                console.log(response);
                return response.data;
            } else {
                return rejectWithValue(response.statusText);
            }
        } catch (e) {
            return rejectWithValue(e.message);
        }
    }
);


// Create Redux slice
const ucdPushNotificationsSlice = createSlice({
    name: 'ucdPushNotifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handling sendNotificationToGroups
            .addCase(sendNotificationToGroups.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(sendNotificationToGroups.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.messages.push(action.payload);
                state.status = 'Group notification sent';
                state.lastMessageStatus = action.payload.messageSendingStatus;
            })
            .addCase(sendNotificationToGroups.rejected, (state, action) => {
                state.loading = 'idle';
                state.status = `Failed to send group notification: ${action.payload}`;
            })

            // Handling sendNotificationToEntireChurch
            .addCase(sendNotificationToEntireChurch.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(sendNotificationToEntireChurch.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.messages.push(action.payload);
                state.status = 'Notification sent to entire church';
                state.lastMessageStatus = action.payload.messageSendingStatus;
            })
            .addCase(sendNotificationToEntireChurch.rejected, (state, action) => {
                state.loading = 'idle';
                state.status = `Failed to send notification to entire church: ${action.payload}`;
            })

            // Handling findChurchMainTopic
            .addCase(findChurchMainTopic.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(findChurchMainTopic.fulfilled, (state, action) => {
                state.loading = 'idle';
                // Assuming the response has a 'mainTopic' property
                state.churchMainTopicId = action.payload.snsTopicId;
                state.status = 'Fetched church main topic';
            })
            .addCase(findChurchMainTopic.rejected, (state, action) => {
                state.loading = 'idle';
                state.status = `Failed to fetch church main topic: ${action.payload}`;
            })
            .addCase(preSendNotification.pending, (state) => {
                    state.loading = 'pending';
            })
            .addCase(preSendNotification.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.totalDevices = action.payload.totalDevices;
                state.totalFamilies = action.payload.totalFamilies;
                state.status = 'Pre-send notification completed';
            })
            .addCase(preSendNotification.rejected, (state, action) => {
                state.loading = 'idle';
                state.status = `Failed to complete pre-send notification: ${action.payload}`;
            })

            .addCase(findNotificationsByChurchId.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(findNotificationsByChurchId.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.previousNotifications = action.payload;
                state.status = 'Notifications by church ID found';
            })
            .addCase(findNotificationsByChurchId.rejected, (state, action) => {
                state.loading = 'idle';
                state.status = `Failed to find notifications by church ID: ${action.payload}`;
            });
    },
});

// Export the reducer
export default ucdPushNotificationsSlice.reducer;
