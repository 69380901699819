import React, { useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { H2Text, CommonFormTextInput } from '../../styles/common-styles.js';
import _ from 'lodash';
import {
  emailAccessCodes,
  setEmailAccessCodesFeedback,
} from '../../reducers/ucdFamilyManagementSlice';

function DirectorySearch({ setSearchTerm }) {
  const searchRef = useRef(null);
  const debouncedSetSearchTerm = _.debounce(setSearchTerm, 300);
  const throttledSetSearchTerm = _.throttle(setSearchTerm, 100);
  const { sessionKey } = useSelector((state) => state.ucdSession);
  const { isChurchAdmin } = useSelector((state) => state.ucdUser);
  const { directoryId } = useSelector((state) => state.ucdChurchDirectory);
  const { emailAccessCodesLoading, familyUpdateResultsMessage } = useSelector(
    (state) => state.ucdFamilyManagement
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel();
      throttledSetSearchTerm.cancel();
    };
  }, [debouncedSetSearchTerm, throttledSetSearchTerm]);

  useEffect(() => {
    switch (emailAccessCodesLoading) {
      case 'success':
        alert(
          'Access Codes have been set to be sent.  Codes will start to arrive in inboxes in a few minutes.'
        );
        setEmailAccessCodesFeedback({
          loadingStatus: 'idle',
          resultMessage: '',
        });
        break;
      case 'failed':
        alert(familyUpdateResultsMessage);
        break;
    }
  }, [emailAccessCodesLoading]);

  const handleTextChange = (text) => {
    throttledSetSearchTerm(text);
    debouncedSetSearchTerm(text);
  };

  function sendCodesToAllFamilies() {
    dispatch(
      emailAccessCodes({
        sessionKey: sessionKey,
        accessCodeFor: 'directory',
        directoryId: directoryId,
      })
    );
  }

  return (
    <SearchContainer>
      <ContactsText>Contacts</ContactsText>
      <SearchBar
        placeholderTextColor={'gray'}
        autoCorrect={false}
        autoCapitalize='none'
        ref={searchRef}
        placeholder='Search'
        onChangeText={handleTextChange}
      />
      <SearchIcon
        source={{
          uri: 'https://directory.ucdir.com/static/media/mag.bc74e481.png',
        }}
      />
      {isChurchAdmin && (
        <AccessCodeSendLink onPress={sendCodesToAllFamilies}>
          <MaterialCommunityIcons
            name='email-send-outline'
            size={32}
            color='#a51d31'
          />
          <AccessCodeSendText>
            Send Access Codes to Entire Directory
          </AccessCodeSendText>
        </AccessCodeSendLink>
      )}
    </SearchContainer>
  );
}

const SearchContainer = styled.View`
  width: 100%;
  height: 80px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
`;

const ContactsText = styled(H2Text)`
  margin-left: 15px;
  margin-right: 15px;
  font-family: sans-serif;
`;

const SearchBar = styled.TextInput`
  height: 35px;
  width: 250px;
  padding-left: 35px;
  font-size: 0.9em;
  background: #fff;
`;

const AccessCodeSendLink = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 20px;
`;

const AccessCodeSendText = styled.Text`
  font-family: sans-serif;
  font-size: 18px;
  margin-left: 10px;
`;

export const SearchIcon = styled.Image`
  width: 15px;
  height: 15px;
  position: absolute;
  left: 165px;
  resizemode: stretch;
`;

export default DirectorySearch;
