import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ucdapi from '../api/ucdapi';

/** STATE OBJECT */
const initialState = {
  familyActiveStatusLoading: 'idle',
  familyAccessCodeLoading: 'idle',
  emailAccessCodesLoading: 'idle',
  uploadFamilyImageLoading: 'idle',
  familyUpdateResultMessage: 'N/A',
  lastEditedFamilyImageId: 'N/A',
  lastEditTimestamp: 'N/A',
  newFamilyAccessCode: '',
};

/** ASYNC ACTIONS */

// Action to set family active status
export const setFamilyActiveStatus = createAsyncThunk(
  'ucdFamilyManagement/setFamilyActiveStatus',
  async (data, { rejectWithValue }) => {
    const response = await ucdapi.post('/set_family_active_status', data);

    if (200 === response.status) {
      if (response.data.success) {
        return response.data.msg;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

// Action to issue a new family access code
export const issueNewFamilyAccessCode = createAsyncThunk(
  'ucdFamilyManagement/issueNewFamilyAccessCode',
  async (data, { rejectWithValue }) => {
    const response = await ucdapi.post('/issue_new_family_access_code', data);

    if (200 === response.status) {
      if (response.data.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data.msg);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

// Action to email access codes
export const emailAccessCodes = createAsyncThunk(
  'ucdFamilyManagement/emailAccessCodes',
  async (data, { rejectWithValue }) => {
    const response = await ucdapi.post('/email_access_codes', data);

    if (200 === response.status) {
      if (response.data.success) {
        return response.data.message;
      } else {
        return rejectWithValue(response.data.message);
      }
    } else {
      return rejectWithValue(response.statusText);
    }
  }
);

// Action to upload family image
export const uploadFamilyImage = createAsyncThunk(
  'ucdFamilyManagement/uploadFamilyImage',
  async ({ formData, familyId }, { rejectWithValue }) => {
    const response = await ucdapi.post('/upload_family_image_file', formData);

    if (response.status === 200 && response.data.success) {
      return { message: response.data.msg, familyId: familyId };
    } else {
      return rejectWithValue(response.data.msg || response.statusText);
    }
  }
);

/** DEFINE REDUCERS */
export const ucdFamilyManagementSlice = createSlice({
  name: 'ucdFamilyManagement',
  initialState,
  reducers: {
    setFamilyActiveStatusFeedback(state, { payload }) {
      state.familyActiveStatusLoading = payload.loadingStatus;
      state.familyUpdateResultMessage = payload.resultMessage;
    },
    setEmailAccessCodesFeedback(state, { payload }) {
      state.emailAccessCodesLoading = payload.loadingStatus;
      state.familyUpdateResultMessage = payload.resultMessage;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling setFamilyActiveStatus
      .addCase(setFamilyActiveStatus.pending, (state) => {
        state.familyActiveStatusLoading = 'pending';
        state.familyUpdateResultMessage = 'Setting Family Active Status...';
      })
      .addCase(setFamilyActiveStatus.fulfilled, (state, { payload }) => {
        state.familyActiveStatusLoading = 'complete';
        state.familyUpdateResultMessage = payload;
      })
      .addCase(setFamilyActiveStatus.rejected, (state, { payload }) => {
        state.familyActiveStatusLoading = 'failed';
        state.familyUpdateResultMessage = payload;
      })
      .addCase(issueNewFamilyAccessCode.pending, (state) => {
        state.familyAccessCodeLoading = 'pending';
        state.familyUpdateResultMessage = 'Issuing New Family Access Code...';
      })
      .addCase(issueNewFamilyAccessCode.fulfilled, (state, { payload }) => {
        state.familyAccessCodeLoading = 'idle';
        state.familyUpdateResultMessage = payload.msg;
        state.newFamilyAccessCode = payload.newAccessCode;
      })
      .addCase(issueNewFamilyAccessCode.rejected, (state, { payload }) => {
        state.familyAccessCodeLoading = 'failed';
        state.familyUpdateResultMessage = payload;
      })
      // Handling emailAccessCodes
      .addCase(emailAccessCodes.pending, (state) => {
        state.emailAccessCodesLoading = 'pending';
        state.familyUpdateResultMessage = 'Emailing Access Codes...';
      })
      .addCase(emailAccessCodes.fulfilled, (state, { payload }) => {
        state.emailAccessCodesLoading = 'success';
        state.familyUpdateResultMessage = payload;
      })
      .addCase(emailAccessCodes.rejected, (state, { payload }) => {
        state.emailAccessCodesLoading = 'failed';
        state.familyUpdateResultMessage = payload;
      })
      //Image upload cases
      .addCase(uploadFamilyImage.pending, (state, { payload }) => {
        state.uploadFamilyImageLoading = 'pending';
      })
      .addCase(uploadFamilyImage.fulfilled, (state, { payload }) => {
        state.uploadFamilyImageLoading = 'idle';
        state.lastEditedFamilyImageId = payload.familyId;
        state.lastEditTimestamp = new Date().getTime();
        console.log('payload?', payload, payload.familyId);
      });
  },
});

const { actions, reducer } = ucdFamilyManagementSlice;

export const { setFamilyActiveStatusFeedback, setEmailAccessCodesFeedback } =
  actions;

export default reducer;
